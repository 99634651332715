.input-group-btn .flag {
   margin: -6px 0px;
}

.flag {
   display: inline-block;
   background: url('./images/flags.png') no-repeat;
   overflow: hidden;
   text-indent: -9999px;
   text-align: left;
}

.fg_ad {
   background-position: -1px -0px;
   width: 24px;
   height: 24px;
}

.fg_ae {
   background-position: -26px -0px;
   width: 24px;
   height: 24px;
}

.fg_af {
   background-position: -51px -0px;
   width: 24px;
   height: 24px;
}

.fg_ag {
   background-position: -76px -0px;
   width: 24px;
   height: 24px;
}

.fg_ai {
   background-position: -101px -0px;
   width: 24px;
   height: 24px;
}

.fg_al {
   background-position: -126px -0px;
   width: 24px;
   height: 24px;
}

.fg_am {
   background-position: -151px -0px;
   width: 24px;
   height: 24px;
}

.fg_an {
   background-position: -176px -0px;
   width: 24px;
   height: 24px;
}

.fg_ao {
   background-position: -201px -0px;
   width: 24px;
   height: 24px;
}

.fg_aq {
   background-position: -226px -0px;
   width: 24px;
   height: 24px;
}

.fg_ar {
   background-position: -251px -0px;
   width: 24px;
   height: 24px;
}

.fg_as {
   background-position: -276px -0px;
   width: 24px;
   height: 24px;
}

.fg_at {
   background-position: -301px -0px;
   width: 24px;
   height: 24px;
}

.fg_au {
   background-position: -326px -0px;
   width: 24px;
   height: 24px;
}

.fg_aw {
   background-position: -351px -0px;
   width: 24px;
   height: 24px;
}

.fg_ax {
   background-position: -1px -25px;
   width: 24px;
   height: 24px;
}

.fg_az {
   background-position: -26px -25px;
   width: 24px;
   height: 24px;
}

.fg_ba {
   background-position: -51px -25px;
   width: 24px;
   height: 24px;
}

.fg_bb {
   background-position: -76px -25px;
   width: 24px;
   height: 24px;
}

.fg_bd {
   background-position: -101px -25px;
   width: 24px;
   height: 24px;
}

.fg_be {
   background-position: -126px -25px;
   width: 24px;
   height: 24px;
}

.fg_bf {
   background-position: -151px -25px;
   width: 24px;
   height: 24px;
}

.fg_bg {
   background-position: -176px -25px;
   width: 24px;
   height: 24px;
}

.fg_bh {
   background-position: -201px -25px;
   width: 24px;
   height: 24px;
}

.fg_bi {
   background-position: -226px -25px;
   width: 24px;
   height: 24px;
}

.fg_bj {
   background-position: -251px -25px;
   width: 24px;
   height: 24px;
}

.fg_bl {
   background-position: -276px -25px;
   width: 24px;
   height: 24px;
}

.fg_bm {
   background-position: -301px -25px;
   width: 24px;
   height: 24px;
}

.fg_bn {
   background-position: -326px -25px;
   width: 24px;
   height: 24px;
}

.fg_bo {
   background-position: -351px -25px;
   width: 24px;
   height: 24px;
}

.fg_bq {
   background-position: -1px -50px;
   width: 24px;
   height: 24px;
}

.fg_br {
   background-position: -26px -50px;
   width: 24px;
   height: 24px;
}

.fg_bs {
   background-position: -51px -50px;
   width: 24px;
   height: 24px;
}

.fg_bt {
   background-position: -76px -50px;
   width: 24px;
   height: 24px;
}

.fg_bv {
   background-position: -101px -50px;
   width: 24px;
   height: 24px;
}

.fg_bw {
   background-position: -126px -50px;
   width: 24px;
   height: 24px;
}

.fg_by {
   background-position: -151px -50px;
   width: 24px;
   height: 24px;
}

.fg_bz {
   background-position: -176px -50px;
   width: 24px;
   height: 24px;
}

.fg_ca {
   background-position: -201px -50px;
   width: 24px;
   height: 24px;
}

.fg_cc {
   background-position: -226px -50px;
   width: 24px;
   height: 24px;
}

.fg_cd {
   background-position: -251px -50px;
   width: 24px;
   height: 24px;
}

.fg_cf {
   background-position: -276px -50px;
   width: 24px;
   height: 24px;
}

.fg_cg {
   background-position: -301px -50px;
   width: 24px;
   height: 24px;
}

.fg_ch {
   background-position: -326px -50px;
   width: 24px;
   height: 24px;
}

.fg_ci {
   background-position: -351px -50px;
   width: 24px;
   height: 24px;
}

.fg_ck {
   background-position: -1px -75px;
   width: 24px;
   height: 24px;
}

.fg_cl {
   background-position: -26px -75px;
   width: 24px;
   height: 24px;
}

.fg_cm {
   background-position: -51px -75px;
   width: 24px;
   height: 24px;
}

.fg_cn {
   background-position: -76px -75px;
   width: 24px;
   height: 24px;
}

.fg_co {
   background-position: -101px -75px;
   width: 24px;
   height: 24px;
}

.fg_cr {
   background-position: -126px -75px;
   width: 24px;
   height: 24px;
}

.fg_cu {
   background-position: -151px -75px;
   width: 24px;
   height: 24px;
}

.fg_cv {
   background-position: -176px -75px;
   width: 24px;
   height: 24px;
}

.fg_cw {
   background-position: -201px -75px;
   width: 24px;
   height: 24px;
}

.fg_cx {
   background-position: -226px -75px;
   width: 24px;
   height: 24px;
}

.fg_cy {
   background-position: -251px -75px;
   width: 24px;
   height: 24px;
}

.fg_cz {
   background-position: -276px -75px;
   width: 24px;
   height: 24px;
}

.fg_de {
   background-position: -301px -75px;
   width: 24px;
   height: 24px;
}

.fg_dj {
   background-position: -326px -75px;
   width: 24px;
   height: 24px;
}

.fg_dk {
   background-position: -351px -75px;
   width: 24px;
   height: 24px;
}

.fg_dm {
   background-position: -1px -100px;
   width: 24px;
   height: 24px;
}

.fg_do {
   background-position: -26px -100px;
   width: 24px;
   height: 24px;
}

.fg_dz {
   background-position: -51px -100px;
   width: 24px;
   height: 24px;
}

.fg_ec {
   background-position: -76px -100px;
   width: 24px;
   height: 24px;
}

.fg_ee {
   background-position: -101px -100px;
   width: 24px;
   height: 24px;
}

.fg_eg {
   background-position: -126px -100px;
   width: 24px;
   height: 24px;
}

.fg_eh {
   background-position: -151px -100px;
   width: 24px;
   height: 24px;
}

.fg_er {
   background-position: -176px -100px;
   width: 24px;
   height: 24px;
}

.fg_es {
   background-position: -201px -100px;
   width: 24px;
   height: 24px;
}

.fg_et {
   background-position: -226px -100px;
   width: 24px;
   height: 24px;
}

.fg_fi {
   background-position: -251px -100px;
   width: 24px;
   height: 24px;
}

.fg_fj {
   background-position: -276px -100px;
   width: 24px;
   height: 24px;
}

.fg_fk {
   background-position: -301px -100px;
   width: 24px;
   height: 24px;
}

.fg_fm {
   background-position: -326px -100px;
   width: 24px;
   height: 24px;
}

.fg_fo {
   background-position: -351px -100px;
   width: 24px;
   height: 24px;
}

.fg_fr {
   background-position: -1px -125px;
   width: 24px;
   height: 24px;
}

.fg_ga {
   background-position: -26px -125px;
   width: 24px;
   height: 24px;
}

.fg_gb {
   background-position: -51px -125px;
   width: 24px;
   height: 24px;
}

.fg_gd {
   background-position: -76px -125px;
   width: 24px;
   height: 24px;
}

.fg_ge {
   background-position: -101px -125px;
   width: 24px;
   height: 24px;
}

.fg_gf {
   background-position: -126px -125px;
   width: 24px;
   height: 24px;
}

.fg_gg {
   background-position: -151px -125px;
   width: 24px;
   height: 24px;
}

.fg_gh {
   background-position: -176px -125px;
   width: 24px;
   height: 24px;
}

.fg_gi {
   background-position: -201px -125px;
   width: 24px;
   height: 24px;
}

.fg_gl {
   background-position: -226px -125px;
   width: 24px;
   height: 24px;
}

.fg_gm {
   background-position: -251px -125px;
   width: 24px;
   height: 24px;
}

.fg_gn {
   background-position: -276px -125px;
   width: 24px;
   height: 24px;
}

.fg_gp {
   background-position: -301px -125px;
   width: 24px;
   height: 24px;
}

.fg_gq {
   background-position: -326px -125px;
   width: 24px;
   height: 24px;
}

.fg_gr {
   background-position: -351px -125px;
   width: 24px;
   height: 24px;
}

.fg_gs {
   background-position: -1px -150px;
   width: 24px;
   height: 24px;
}

.fg_gt {
   background-position: -26px -150px;
   width: 24px;
   height: 24px;
}

.fg_gu {
   background-position: -51px -150px;
   width: 24px;
   height: 24px;
}

.fg_gw {
   background-position: -76px -150px;
   width: 24px;
   height: 24px;
}

.fg_gy {
   background-position: -101px -150px;
   width: 24px;
   height: 24px;
}

.fg_hk {
   background-position: -126px -150px;
   width: 24px;
   height: 24px;
}

.fg_hm {
   background-position: -151px -150px;
   width: 24px;
   height: 24px;
}

.fg_hn {
   background-position: -176px -150px;
   width: 24px;
   height: 24px;
}

.fg_hr {
   background-position: -201px -150px;
   width: 24px;
   height: 24px;
}

.fg_ht {
   background-position: -226px -150px;
   width: 24px;
   height: 24px;
}

.fg_hu {
   background-position: -251px -150px;
   width: 24px;
   height: 24px;
}

.fg_id {
   background-position: -276px -150px;
   width: 24px;
   height: 24px;
}

.fg_ie {
   background-position: -301px -150px;
   width: 24px;
   height: 24px;
}

.fg_il {
   background-position: -326px -150px;
   width: 24px;
   height: 24px;
}

.fg_im {
   background-position: -351px -150px;
   width: 24px;
   height: 24px;
}

.fg_in {
   background-position: -1px -175px;
   width: 24px;
   height: 24px;
}

.fg_io {
   background-position: -26px -175px;
   width: 24px;
   height: 24px;
}

.fg_iq {
   background-position: -51px -175px;
   width: 24px;
   height: 24px;
}

.fg_ir {
   background-position: -76px -175px;
   width: 24px;
   height: 24px;
}

.fg_is {
   background-position: -101px -175px;
   width: 24px;
   height: 24px;
}

.fg_it {
   background-position: -126px -175px;
   width: 24px;
   height: 24px;
}

.fg_je {
   background-position: -151px -175px;
   width: 24px;
   height: 24px;
}

.fg_jm {
   background-position: -176px -175px;
   width: 24px;
   height: 24px;
}

.fg_jo {
   background-position: -201px -175px;
   width: 24px;
   height: 24px;
}

.fg_jp {
   background-position: -226px -175px;
   width: 24px;
   height: 24px;
}

.fg_ke {
   background-position: -251px -175px;
   width: 24px;
   height: 24px;
}

.fg_kg {
   background-position: -276px -175px;
   width: 24px;
   height: 24px;
}

.fg_kh {
   background-position: -301px -175px;
   width: 24px;
   height: 24px;
}

.fg_ki {
   background-position: -326px -175px;
   width: 24px;
   height: 24px;
}

.fg_km {
   background-position: -351px -175px;
   width: 24px;
   height: 24px;
}

.fg_kn {
   background-position: -1px -200px;
   width: 24px;
   height: 24px;
}

.fg_kp {
   background-position: -26px -200px;
   width: 24px;
   height: 24px;
}

.fg_kr {
   background-position: -51px -200px;
   width: 24px;
   height: 24px;
}

.fg_kw {
   background-position: -76px -200px;
   width: 24px;
   height: 24px;
}

.fg_ky {
   background-position: -101px -200px;
   width: 24px;
   height: 24px;
}

.fg_kz {
   background-position: -126px -200px;
   width: 24px;
   height: 24px;
}

.fg_la {
   background-position: -151px -200px;
   width: 24px;
   height: 24px;
}

.fg_lb {
   background-position: -176px -200px;
   width: 24px;
   height: 24px;
}

.fg_lc {
   background-position: -201px -200px;
   width: 24px;
   height: 24px;
}

.fg_li {
   background-position: -226px -200px;
   width: 24px;
   height: 24px;
}

.fg_lk {
   background-position: -251px -200px;
   width: 24px;
   height: 24px;
}

.fg_lr {
   background-position: -276px -200px;
   width: 24px;
   height: 24px;
}

.fg_ls {
   background-position: -301px -200px;
   width: 24px;
   height: 24px;
}

.fg_lt {
   background-position: -326px -200px;
   width: 24px;
   height: 24px;
}

.fg_lu {
   background-position: -351px -200px;
   width: 24px;
   height: 24px;
}

.fg_lv {
   background-position: -1px -225px;
   width: 24px;
   height: 24px;
}

.fg_ly {
   background-position: -26px -225px;
   width: 24px;
   height: 24px;
}

.fg_ma {
   background-position: -51px -225px;
   width: 24px;
   height: 24px;
}

.fg_mc {
   background-position: -76px -225px;
   width: 24px;
   height: 24px;
}

.fg_md {
   background-position: -101px -225px;
   width: 24px;
   height: 24px;
}

.fg_me {
   background-position: -126px -225px;
   width: 24px;
   height: 24px;
}

.fg_mf {
   background-position: -151px -225px;
   width: 24px;
   height: 24px;
}

.fg_mg {
   background-position: -176px -225px;
   width: 24px;
   height: 24px;
}

.fg_mh {
   background-position: -201px -225px;
   width: 24px;
   height: 24px;
}

.fg_mk {
   background-position: -226px -225px;
   width: 24px;
   height: 24px;
}

.fg_ml {
   background-position: -251px -225px;
   width: 24px;
   height: 24px;
}

.fg_mm {
   background-position: -276px -225px;
   width: 24px;
   height: 24px;
}

.fg_mn {
   background-position: -301px -225px;
   width: 24px;
   height: 24px;
}

.fg_mo {
   background-position: -326px -225px;
   width: 24px;
   height: 24px;
}

.fg_mp {
   background-position: -351px -225px;
   width: 24px;
   height: 24px;
}

.fg_mq {
   background-position: -1px -250px;
   width: 24px;
   height: 24px;
}

.fg_mr {
   background-position: -26px -250px;
   width: 24px;
   height: 24px;
}

.fg_ms {
   background-position: -51px -250px;
   width: 24px;
   height: 24px;
}

.fg_mt {
   background-position: -76px -250px;
   width: 24px;
   height: 24px;
}

.fg_mu {
   background-position: -101px -250px;
   width: 24px;
   height: 24px;
}

.fg_mv {
   background-position: -126px -250px;
   width: 24px;
   height: 24px;
}

.fg_mw {
   background-position: -151px -250px;
   width: 24px;
   height: 24px;
}

.fg_mx {
   background-position: -176px -250px;
   width: 24px;
   height: 24px;
}

.fg_my {
   background-position: -201px -250px;
   width: 24px;
   height: 24px;
}

.fg_mz {
   background-position: -226px -250px;
   width: 24px;
   height: 24px;
}

.fg_na {
   background-position: -251px -250px;
   width: 24px;
   height: 24px;
}

.fg_nc {
   background-position: -276px -250px;
   width: 24px;
   height: 24px;
}

.fg_ne {
   background-position: -301px -250px;
   width: 24px;
   height: 24px;
}

.fg_nf {
   background-position: -326px -250px;
   width: 24px;
   height: 24px;
}

.fg_ng {
   background-position: -351px -250px;
   width: 24px;
   height: 24px;
}

.fg_ni {
   background-position: -1px -275px;
   width: 24px;
   height: 24px;
}

.fg_nl {
   background-position: -26px -275px;
   width: 24px;
   height: 24px;
}

.fg_no {
   background-position: -51px -275px;
   width: 24px;
   height: 24px;
}

.fg_np {
   background-position: -76px -275px;
   width: 24px;
   height: 24px;
}

.fg_nr {
   background-position: -101px -275px;
   width: 24px;
   height: 24px;
}

.fg_nu {
   background-position: -126px -275px;
   width: 24px;
   height: 24px;
}

.fg_nz {
   background-position: -151px -275px;
   width: 24px;
   height: 24px;
}

.fg_om {
   background-position: -176px -275px;
   width: 24px;
   height: 24px;
}

.fg_pa {
   background-position: -201px -275px;
   width: 24px;
   height: 24px;
}

.fg_pe {
   background-position: -226px -275px;
   width: 24px;
   height: 24px;
}

.fg_pf {
   background-position: -251px -275px;
   width: 24px;
   height: 24px;
}

.fg_pg {
   background-position: -276px -275px;
   width: 24px;
   height: 24px;
}

.fg_ph {
   background-position: -301px -275px;
   width: 24px;
   height: 24px;
}

.fg_pk {
   background-position: -326px -275px;
   width: 24px;
   height: 24px;
}

.fg_pl {
   background-position: -351px -275px;
   width: 24px;
   height: 24px;
}

.fg_pm {
   background-position: -1px -300px;
   width: 24px;
   height: 24px;
}

.fg_pn {
   background-position: -26px -300px;
   width: 24px;
   height: 24px;
}

.fg_pr {
   background-position: -51px -300px;
   width: 24px;
   height: 24px;
}

.fg_ps {
   background-position: -76px -300px;
   width: 24px;
   height: 24px;
}

.fg_pt {
   background-position: -101px -300px;
   width: 24px;
   height: 24px;
}

.fg_pw {
   background-position: -126px -300px;
   width: 24px;
   height: 24px;
}

.fg_py {
   background-position: -151px -300px;
   width: 24px;
   height: 24px;
}

.fg_qa {
   background-position: -176px -300px;
   width: 24px;
   height: 24px;
}

.fg_re {
   background-position: -201px -300px;
   width: 24px;
   height: 24px;
}

.fg_ro {
   background-position: -226px -300px;
   width: 24px;
   height: 24px;
}

.fg_rs {
   background-position: -251px -300px;
   width: 24px;
   height: 24px;
}

.fg_ru {
   background-position: -276px -300px;
   width: 24px;
   height: 24px;
}

.fg_rw {
   background-position: -301px -300px;
   width: 24px;
   height: 24px;
}

.fg_sa {
   background-position: -326px -300px;
   width: 24px;
   height: 24px;
}

.fg_sb {
   background-position: -351px -300px;
   width: 24px;
   height: 24px;
}

.fg_sc {
   background-position: -1px -325px;
   width: 24px;
   height: 24px;
}

.fg_sd {
   background-position: -26px -325px;
   width: 24px;
   height: 24px;
}

.fg_se {
   background-position: -51px -325px;
   width: 24px;
   height: 24px;
}

.fg_sg {
   background-position: -76px -325px;
   width: 24px;
   height: 24px;
}

.fg_sh {
   background-position: -101px -325px;
   width: 24px;
   height: 24px;
}

.fg_si {
   background-position: -126px -325px;
   width: 24px;
   height: 24px;
}

.fg_sj {
   background-position: -151px -325px;
   width: 24px;
   height: 24px;
}

.fg_sk {
   background-position: -176px -325px;
   width: 24px;
   height: 24px;
}

.fg_sl {
   background-position: -201px -325px;
   width: 24px;
   height: 24px;
}

.fg_sm {
   background-position: -226px -325px;
   width: 24px;
   height: 24px;
}

.fg_sn {
   background-position: -251px -325px;
   width: 24px;
   height: 24px;
}

.fg_so {
   background-position: -276px -325px;
   width: 24px;
   height: 24px;
}

.fg_sr {
   background-position: -301px -325px;
   width: 24px;
   height: 24px;
}

.fg_ss {
   background-position: -326px -325px;
   width: 24px;
   height: 24px;
}

.fg_st {
   background-position: -351px -325px;
   width: 24px;
   height: 24px;
}

.fg_sv {
   background-position: -1px -350px;
   width: 24px;
   height: 24px;
}

.fg_sx {
   background-position: -26px -350px;
   width: 24px;
   height: 24px;
}

.fg_sy {
   background-position: -51px -350px;
   width: 24px;
   height: 24px;
}

.fg_sz {
   background-position: -76px -350px;
   width: 24px;
   height: 24px;
}

.fg_tc {
   background-position: -101px -350px;
   width: 24px;
   height: 24px;
}

.fg_td {
   background-position: -126px -350px;
   width: 24px;
   height: 24px;
}

.fg_tf {
   background-position: -151px -350px;
   width: 24px;
   height: 24px;
}

.fg_tg {
   background-position: -176px -350px;
   width: 24px;
   height: 24px;
}

.fg_th {
   background-position: -201px -350px;
   width: 24px;
   height: 24px;
}

.fg_tj {
   background-position: -226px -350px;
   width: 24px;
   height: 24px;
}

.fg_tk {
   background-position: -251px -350px;
   width: 24px;
   height: 24px;
}

.fg_tl {
   background-position: -276px -350px;
   width: 24px;
   height: 24px;
}

.fg_tm {
   background-position: -301px -350px;
   width: 24px;
   height: 24px;
}

.fg_tn {
   background-position: -326px -350px;
   width: 24px;
   height: 24px;
}

.fg_to {
   background-position: -351px -350px;
   width: 24px;
   height: 24px;
}

.fg_tr {
   background-position: -1px -375px;
   width: 24px;
   height: 24px;
}

.fg_tt {
   background-position: -26px -375px;
   width: 24px;
   height: 24px;
}

.fg_tv {
   background-position: -51px -375px;
   width: 24px;
   height: 24px;
}

.fg_tw {
   background-position: -76px -375px;
   width: 24px;
   height: 24px;
}

.fg_tz {
   background-position: -101px -375px;
   width: 24px;
   height: 24px;
}

.fg_ua {
   background-position: -126px -375px;
   width: 24px;
   height: 24px;
}

.fg_ug {
   background-position: -151px -375px;
   width: 24px;
   height: 24px;
}

.fg_um {
   background-position: -176px -375px;
   width: 24px;
   height: 24px;
}

.fg_us {
   background-position: -201px -375px;
   width: 24px;
   height: 24px;
}

.fg_uy {
   background-position: -226px -375px;
   width: 24px;
   height: 24px;
}

.fg_uz {
   background-position: -251px -375px;
   width: 24px;
   height: 24px;
}

.fg_va {
   background-position: -276px -375px;
   width: 24px;
   height: 24px;
}

.fg_vc {
   background-position: -301px -375px;
   width: 24px;
   height: 24px;
}

.fg_ve {
   background-position: -326px -375px;
   width: 24px;
   height: 24px;
}

.fg_vg {
   background-position: -351px -375px;
   width: 24px;
   height: 24px;
}

.fg_vi {
   background-position: -1px -400px;
   width: 24px;
   height: 24px;
}

.fg_vn {
   background-position: -26px -400px;
   width: 24px;
   height: 24px;
}

.fg_vu {
   background-position: -51px -400px;
   width: 24px;
   height: 24px;
}

.fg_wf {
   background-position: -76px -400px;
   width: 24px;
   height: 24px;
}

.fg_ws {
   background-position: -101px -400px;
   width: 24px;
   height: 24px;
}

.fg_xk {
   background-position: -126px -400px;
   width: 24px;
   height: 24px;
}

.fg_ye {
   background-position: -151px -400px;
   width: 24px;
   height: 24px;
}

.fg_yt {
   background-position: -176px -400px;
   width: 24px;
   height: 24px;
}

.fg_za {
   background-position: -201px -400px;
   width: 24px;
   height: 24px;
}

.fg_zm {
   background-position: -226px -400px;
   width: 24px;
   height: 24px;
}

.fg_zw {
   background-position: -251px -400px;
   width: 24px;
   height: 24px;
}

.fg_ {
   background-position: -276px -400px;
   width: 24px;
   height: 24px;
}
