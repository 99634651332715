/**
 * This file contains the container layout styles, all of which can be @extend from component SCSS
 */
.container-scroll {
    overflow-y: auto;
    max-height: 100%;

    // & > :first-child {
    //     width: 100%;
    // }
}

/*
 * For this to work all of the parent containers need to have overflow: hidden; set.
 * https://stackoverflow.com/a/35609992/160056
 */
.container-with-footer {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-height: inherit;
    min-height: 0;

    & > :first-child {
        flex: 1;
        overflow-y: auto;
        width: 100%;
        // https://moduscreate.com/blog/how-to-fix-overflow-issues-in-css-flex-layouts/
        min-height: 0;
    }
}

.vertical-container {
    display: flex;
    flex-direction: column;

    &.wrap {
        flex-wrap: wrap;
    }

    &.gap {
        gap: 5px;
    }
}

.horizontal-container {
    display: flex;
    flex-direction: row;

    &.wrap {
        flex-wrap: wrap;
    }

    &.gap {
        gap: 5px;
    }

    &.tiled-children {
        flex-wrap: wrap;
    }

    /* Make all children */
    &.tiled-children > * {
        flex: 1;
        margin-top: 14px;
        padding: 1% 0;
    }

    > .form-group {
        flex: 1;
        padding-right: 10px;
    }
}

.pad-children > * {
    margin-right: 5px;
}

.mdc-children {
    height: 48px;
}

.action-container {
    padding-top: $modal-inner-padding;
    text-align: right;

    button,
    a,
    div.btn-group {
        margin-left: 5px;
    }
}

.center-children {
    align-items: center;
}

.fill {
    flex: 1;
    min-height: 0;
    min-width: 0;
}

.fill-spacing {
    padding-right: 5px;
}

/**
 * Generate fcol (flex columns based on BS5), and fill (weighted flex grow) for different breakpoints
 */
@mixin make-flex-grid($class) {
    .fcol-#{$class}h {
        flex: 0 0 auto;
        width: calc(100% / 12 * 0.5);
    }

    @for $i from 1 through 11 {
        .fill-#{$class}#{$i} {
            flex: #{$i} 0 auto;
        }
        .fcol-#{$class}#{$i} {
            flex: 0 0 auto;
            width: calc(100% / 12 * #{$i});
        }
        .fcol-#{$class}#{$i}h {
            flex: 0 0 auto;
            width: calc(100% / 12 * #{$i}0.5);
        }
    }

    .fcol-#{$class}12 {
        flex: 12 0 auto;
    }

    .fill-#{$class}12 {
        flex: 0 0 auto;
        width: 100%;
    }
}

@include make-flex-grid('');
@media (max-width: $screen-xs-max) {
    @include make-flex-grid(xs-);
}
@media (min-width: $screen-sm-min) {
    @include make-flex-grid(sm-);
}
@media (min-width: $screen-md-min) {
    @include make-flex-grid(md-);
}
@media (min-width: $screen-lg-min) {
    @include make-flex-grid(lg-);
}

.flex-centered {
    justify-content: center;
}

.space-between {
    justify-content: space-between;
}

.within-parent {
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
}

.contents {
    padding-left: 15px;
    padding-right: 15px;
}

/**
 * adds support for navigation menu
 */
.container-with-navigation {
    .container-menu {
        padding-right: 20px;
        margin: 16px 8px 0 0 ;
        .item {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            .indicator {
                width: 20px;
                min-width: 20px;
            }
            // a {
            //     @extend .btn;
            //     @extend .btn-primary;
            //     flex: 1;
            //     border-radius: 5px !important;
            // }
            button {
                display: flex;
                gap: 8px;
                margin-bottom: 2px;
                border: none;
                align-items: baseline;
                text-align: left;
                padding: 8px;
                background: none;
                color: #162233;
                flex: 1;
                border-radius: 3px !important;
                    &:hover,
                    &:active,
                    &:focus {
                    color: #162233;
                    background-color: #E9ECEF;

            }
            
             }
        }
    }
    @media (max-width: $screen-xs-max) {
        .container-menu {
            position: relative;

            width: 100%;
            min-width: 100%;
        }

        .container-content {
            padding-left: 0px;
            overflow-y: auto;
        }
    }

    @media (min-width: $screen-xs-max) {
        overflow-y: auto;
        .container-menu {
            position: absolute;

            width: 250px;
        }

        .container-content {
            padding-left: 250px;
        }
    }
}
