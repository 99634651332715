.btn-default,
.btn-default-action,
.btn-primary,
.btn-tertiary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {
    border-width: 1px;
    border-style: solid;
}

.btn {
    &:hover,
    &:active,
    &:focus {
        border-color: $color-primary-hover;
        outline: 0 !important;
        box-shadow: none;
        border-color: transparent;
    }
}

.btn-default,
.btn-default-action,
.btn-primary {
    border-radius: 0;
}

.btn-default-action {
    border-color: $color-primary;
    color: $color-primary;
    background-color: $white;
    &.active {
        background-color: $btn-primary-bg;
        border-color: $btn-primary-border;
        color: $btn-primary-color;
        &:hover,
        &:focus {
            border-color: darken($color-primary-hover, 10%);
            background-color: darken($color-primary-hover, 10%);
            color: $btn-primary-color;
        }
    }
    &:active {
        background-color: darken($white, 10%);
        border-color: darken($brand-primary, 12%);
        color: darken($brand-primary, 12%);
    }
    &:hover,
    &:focus {
        color: $btn-primary-color;
        background-color: darken($btn-primary-border, 10%);
        border-color: darken($btn-primary-bg, 12%);
    }
}

.btn-sm {
    border-radius: 3px;
    font-size: 15px;
}

.btn-xs {
    border-radius: 3px;
    padding: 1px 7px;
}

.btn-pill {
    background-color: $white;
    border-color: #ccc;
    color: #333;
    margin-right: 5px;
    &.active {
        background-color: $btn-primary-bg;
        border-color: $btn-primary-border;
        color: $btn-primary-color;
        &:hover,
        &:focus {
            border-color: darken($color-primary-hover, 10%);
            background-color: darken($color-primary-hover, 10%);
            color: $btn-primary-color;
        }
    }
    &:active {
        background-color: darken($white, 10%);
        border-color: darken($brand-primary, 12%);
        color: darken($brand-primary, 12%);
    }
    &:hover,
    &:focus {
        border-color: $color-primary-hover;
        color: $color-primary-hover;
    }
    &.btn-sm {
        border-radius: 20px;
    }
    &.btn-xs {
        border-radius: 15px;
    }

    &.btn-primary {
        color: $brand-primary;
        border-color: $brand-primary;
        &.active {
            background-color: $btn-primary-bg;
            border-color: $btn-primary-border;
            color: $btn-primary-color;
            border-width: 2px;
            &:hover,
            &:focus {
                border-color: darken($color-primary-hover, 10%);
                background-color: darken($color-primary-hover, 10%);
                color: $btn-primary-color;
            }
        }
        &:active {
            background-color: darken($white, 10%);
            border-color: darken($brand-primary, 12%);
            color: darken($brand-primary, 12%);
        }
        &:hover,
        &:focus {
            // border-color: $color-primary-hover;
            // color: $color-primary-hover;
            color: $btn-primary-color;
            background-color: darken($btn-primary-border, 10%);
            border-color: darken($btn-primary-bg, 12%);
        }
    }
    &.btn-expired {
        &:hover {
            color: #fff;
        }
    }
    &.btn-unavailable {
        &:hover {
            color: $color-brand-dark-gray;
        }
    }
}

.btn-default {
    &:hover,
    &:focus {
        background-color: darken($btn-default-bg, 12%);
        border-color: transparent;
        color: #333;
    }

    // For normal button clicks
    &:active {
        background-color: #d4d4d4;
        border-color: #8c8c8c;
    }
    // For radio buttons
    &.active {
        background-color: black;
        border-color: black;
        color: white;
        &:hover,
        &:focus {
            background-color: lighten(black, 12%);
            color: white;
            // border-color: transparent;
        }
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &,
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: darken($btn-default-bg, 12%);
            background-image: none;
        }
    }
}

.btn-pill-link {
    border-radius: 0.5em;
    padding: 0 10px;
    font-size: 12px;
    margin: 0;
    margin-left: 10px;
    height: 18px;
    border-width: 1px;
    background-color: #fff;
    color: #007dd5;
    border-color: #007dd5;
}

.btn-square {
    border-radius: 0px;
}

.btn-primary {
    &:focus {
        outline: 0;
        box-shadow: none;
    }
}

/* This is used for buttons that are overlayed on other items, such as edit buttons on images */
.btn-overlay {
    color: rgba(255, 255, 255, 0.6);
    background: rgba(0, 0, 0, 0.2);
    padding: 6px;
    border: none;
    border-radius: 6px;
}

.btn-overlay:hover {
    color: rgba(255, 255, 255, 1);
}

// Overrider btn-danger
.btn-danger {
    background-color: white;
    color: $color-sunset-orange;
}

// Use default bootstrap btn-danger styles for expired and overdue buttons
.btn-expired {
    @extend .btn-danger;
    background-color: $color-bs-red;
    color: #fff;
}

// Use default bootstrap button-variant mixin styles for unavailable buttons
.btn-unavailable {
    @include button-variant(
        $color-brand-dark-gray,
        $color-mischka,
        $color-mischka
    );
}

.circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    width: 26px;
    background-color: white;
    border-radius: 50%;
    border: 1px solid;
    padding: 1px 6px;
    font-size: 14px;
}

.btn-circle {
    @extend .circle;
    border-color: $color-primary;
    color: $color-primary;
    margin-right: 5px;
    cursor: pointer;
    opacity: 0.65;

    &:hover,
    &:active,
    &:focus {
        border-color: $color-primary-hover;
        opacity: 1;
        text-decoration: none;
    }

    &:disabled,
    &:disabled:hover {
        border-color: $btn-link-disabled-color;
        color: $btn-link-disabled-color;
        opacity: 0.65;
    }

    .fa,
    .fas {
        width: 14px;
        height: 14px;
    }
}

/* This is used for small actions, like 'remove' or 'clear' that sit next on a table row.
 */
.btn-tertiary {
    @include button-variant(#000, transparent, #d5d5dc);
    border: none;
    border-radius: 50%;
    padding: 1px 6px;

    &.btn,
    &.btn-sm {
        width: 26px;
        height: 26px;
        font-size: 14px;
        margin: auto 6px;
    }

    &.btn-link:hover {
        background-color: transparent;
    }

    &:hover {
        background-color: #0077ff33;
    }

    &:focus {
        outline: none;
    }

    &.btn-delete:hover {
        background-color: #f2482633;
    }

    .fa,
    .fas,
    .fal {
        width: 14px;
        height: 14px;
    }
}

.btn-confirm {
    color: $color-primary-dull;
    border: 1px solid $color-primary-dull;
    outline: 0;
}

.btn-wide {
    flex: 1;
    border-radius: 5px;
    &:hover {
        background-color: $color-primary;
        color: $color-white;
        border-color: $color-white;
    }
    &:active {
        background-color: $color-white;
    }
    &.inactive {
        background-color: $color-comet-light;
        color: $color-comet;
        border-color: $color-comet;
        cursor: default;
    }
}

.btn-golden {
    background-color: $color-brand-golden;
    border: 1px solid lighten($color-brand-golden, 20);
    color: $color-brand-gray;

    &:hover,
    &:active {
        color: $color-brand-light-gray;
    }
}

.btn {
    border-width: 1px;
}

/**
 * An inline button that doesn't push out the line height
 */
.btn-inline {
    background-color: transparent;
    padding: 0 3px;
    &.btn-link {
        border-width: 0;
        line-height: inherit;
    }
}
.btn-base-narrow {
    gap: 6px;
    align-items: baseline;
    padding: 4px 16px;
}
.btn-base-narrow:hover {
    background-color: darken($btn-primary-border, 10%);
    border-color: darken($btn-primary-bg, 12%);
}
.btn-base {
    gap: 6px;
    align-items: baseline;
    padding: 6px 16px;
    white-space: nowrap;
}

.corner-curve {
    border-radius: 6px;
}
.corner-right-curve {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
.corner-left-curve {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
.corner-full-curve {
    border-radius: 16px;
}
.circle-btn-icon {
    display: flex;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.circle-btn-icon[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}

.btn-section-action {
    @extend .btn-block;
    text-align: center;
    border-color: $color-primary-dull;
    color: $color-primary-dull;
    background-color: white;
    font-weight: bold;
    border-style: dashed;

    &:hover,
    &:active,
    &:focus {
        color: $color-primary-hover;
        opacity: 1;
        text-decoration: none;
        border-color: $color-primary-hover;
        .circle {
            border-color: $color-primary-hover;
            color: $color-primary-hover;
        }
    }

    .circle {
        @extend .circle;
        border-color: $color-primary-dull;
        color: $color-primary-dull;
    }
}

.btn-dark-back {
    &:focus {
        border: 2px solid $color-primary-dull;
        color: $color-primary-dull;
    }
}

.airspace-check-button {
    min-height: 50px;
    background-color: #007dd5;
    min-width: 100%;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    display: flex;
    line-height: 2em;
    text-transform: uppercase;
    cursor: pointer;
    --fa-primary-opacity: 1;
    --fa-secondary-opacity: 1;
    span {
        font-size: 2em;
    }
    &.auth-button {
        background-color: #000;
        color: #fff;
        --fa-secondary-color: #fad65c;
        --fa-primary-color: #fad65c;
        line-height: 2em;
        text-align: left;
    }
    &.idle {
        background-color: #007dd5;
        color: #fff;
    }
    &.incomplete {
        background-color: #979797;
        color: #fff;
    }
    &.cannot-fly {
        background-color: $color-bs-red;
        --fa-secondary-color: #fff;
        --fa-primary-color: #dc3545;
        color: #fff;
    }
    &.can-fly-conditions {
        background-color: #fad65c;
        --fa-secondary-color: #fff;
        --fa-primary-color: #595974;
    }
    &.can-fly {
        background-color: #00de7a;
        --fa-secondary-color: #000;
        --fa-primary-color: #00de7a;
        color: #000;
    }
    /* This is to align the button text and icon properly */
    span {
        margin-left: -0.5em;
    }
}

.caa-button {
    min-height: 50px;
    min-width: 100%;
    border-width: 0;
    border-radius: 6px 6px 0 0;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
    text-align: left;
    padding: 10px 16px;
    background-color: #000 !important;
    color: #fff !important;
    span {
        font-size: 2em;
        margin-right: 1rem;
        color: #fff !important;
    }
    &.authorisation-available,
    &.authorisation-pending {
        span {
            color: $color-attention !important;
        }
    }
    &.authorisation-incomplete {
        span {
            color: $color-attention-disabled !important;
        }
    }
    &.authorisation-unavailable {
        span {
            color: $color-unavailable !important;
        }
    }
    &.authorisation-pass {
        background-color: $color-pass !important;
    }
    &.authorisation-fail {
        background-color: $color-block !important;
    }
    &.authorisation-closed {
        background-color: $color-complete !important;
    }
}
