%table-cells {
    @include fontStyle($color-tundora, $font-size-base, $fontSemi, 16px);
    border-top: none;
    padding: 6px 10px;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
}

%table-th {
    text-align: left;
}

%table-td {
    text-align: left;
    font-weight: 400;
    &.text-centered,
    &.text-center {
        text-align: center;
        vertical-align: top;
    }
}

%table-actions {
    text-align: right;
}

.sort-desc-indicator,
.sort-asc-indicator {
    display: none;
}

.st-sort-descent > .sort-desc-indicator {
    display: inline;
}

.st-sort-ascent > .sort-asc-indicator {
    display: inline;
}

.st-sort-descent > .sort-unsorted-indicator {
    display: none;
}

.st-sort-ascent > .sort-unsorted-indicator {
    display: none;
}

flyfreely-table {
    display: block;
    overflow-x: auto;
}

flyfreely-table th {
    cursor: pointer;
}

flyfreely-table td .btn-link {
    padding: 0;
    border: 0;
}

.table-container-no-scroll {
    .static-table-container {
        overflow-y: hidden;
    }
}

// This will give table horizontal scroll.
.table-container,
.static-table-container {
    width: auto;
    overflow-x: auto;
    overflow-y: visible;
    padding-bottom: 1px;

    &.no-actions {
        margin-right: 0;
    }

    &.vertical-scroll {
        min-height: 0;
        overflow-y: auto;
    }

    .table > thead > tr > td > ng-select .ng-select-container {
        @extend .input-sm;
        height: $input-height-small;
        min-height: $input-height-small;
        padding: 0;
    }

    &.table-borderless {
        .table > thead > tr > td {
            border: 0;
            padding: 1px;
        }
        .table > thead > tr > th {
            border: 0;
            padding: 1px;
        }
    }

    .disabled {
        color: $gray-lighter;
    }

    .inner-addon {
        position: relative;
    }

    .inner-addon .fa {
        position: absolute;
        padding: 7px;
        pointer-events: none;
    }

    .left-addon .fa {
        left: 0px;
    }
    .right-addon .fa {
        right: 0px;
    }

    .left-addon input {
        padding-left: 26px;
    }
    .right-addon input {
        padding-right: 30px;
    }
}

.table-container {
    margin-right: 3.5em;

    table > thead > tr > th.actions,
    .table > thead > tr > td.actions,
    .table > tbody > tr > td.actions {
        @extend %table-actions;
        position: absolute;
        display: inline-block;
        right: 5px;
        padding: 0;
    }

    &.side-sheet-table {
        .table > thead > tr > th.actions,
        .table > tbody > tr > td.actions {
            right: 10px;

            .btn.btn-tertiary {
                margin: auto;
                margin-left: 5px;
            }
        }
    }

    /* Does this table contain form controls which will force the row heights */
    &.form > .table > tbody > tr > td.actions {
        padding: 5px 0;
        line-height: 35px;
    }
}

.static-table-container {
    margin-right: 2.5em;

    .table > thead > tr > th.actions,
    .table > thead > tr > td.actions,
    .table > tbody > tr > td.actions {
        @extend %table-actions;
        position: absolute;
        display: inline-block;
        padding: 1px;
        right: 0;
    }

    &.action-wide {
        margin-right: 3.5em;

        th.actions {
            width: 4.2em;
            button.btn.btn-tertiary {
                margin-right: 2.1em;
                transform: translateX(50%);
            }
        }

        .table > thead > tr > th.actions,
        .table > thead > tr > td.actions,
        .table > tbody > tr > td.actions {
            @extend %table-actions;
            position: absolute;
            display: inline-block;
            right: 5px;
            padding: 0;
            border: none;
            &.comfortable {
                height: 100%;
                line-height: 50px;
            }
        }

        .btn.btn-tertiary {
            margin: auto;
            margin-left: 5px;
        }
    }

    .table > tbody > tr > td.unseen {
        background-color: #fdf1ba;
        &.actions {
            min-width: 5em;
        }
        &.first {
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
        }
        &.last {
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
        }
    }
}

.ai-table {
    width: 100%;
    max-width: 100%;
    border: none;

    .inner-addon {
        position: relative;
    }

    .inner-addon .glyphicon {
        position: absolute;
        padding: 7px;
        pointer-events: none;
    }

    .left-addon .glyphicon {
        left: 0px;
    }
    .right-addon .glyphicon {
        right: 0px;
    }

    .left-addon input {
        padding-left: 26px;
    }
    .right-addon input {
        padding-right: 30px;
    }

    tbody > tr > td {
        &:last-child {
            right: 0;
            background: $color-alabaster;
            position: absolute;
            display: inline-block;

            &.tr-column-inline {
                right: 0;
            }
        }
    }

    // If you want a column to be fixed, add this to th and td.
    .fixed-column {
        right: 20px;
        top: 90px;
        position: absolute;
        height: 37px;
        background-color: $color-alabaster;
    }

    thead th {
        @extend %table-th;
        text-align: center;
        border-bottom: 0.5px solid #f5f5f5;
        padding: 1px;

        &.th-min-width {
            min-width: 80px;
        }

        &:last-child {
            border-bottom: none;
            &.th-column-inline {
                position: absolute;
                right: 24px;
                top: 90px;
            }
        }
    }

    td,
    th {
        @extend %table-cells;
    }
    td {
        @extend %table-td;
    }
    .fa-circle {
        color: $color-torch-red;
        padding-right: 5px;
    }
    .glyphicon-option-horizontal {
        color: #000;
    }
}

.table > thead > tr > th {
    @extend %table-cells;
    @extend %table-th;
    &.centered-heading {
        text-align: center;
    }
    &.wordwrap {
        white-space: normal;
    }
    &.sortable {
        cursor: pointer;

        // BAD, should be a more contextual class
        span.fa-sort {
            color: $color-brand-light-gray;
        }
    }
}

.table > tbody > tr > td {
    @extend %table-cells;
    @extend %table-td;
    &.wordwrap {
        white-space: normal;
    }
    &.formly-td {
        vertical-align: top;
        min-width: 100px;
    }
}

.table > thead > tr > th.actions,
.table > tbody > tr > td.actions {
    @extend %table-actions;
}

// Styles for ngx-easy-table
.ngx-form-icon {
    height: 1.3rem !important;
    width: 1.3rem !important;
}

// .count-buttons {
//     @include pagination-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $border-radius-small);
// }

%footer-buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    button {
        background: white !important;
        padding: $padding-small-vertical $padding-small-horizontal;
        border: 1px solid $pagination-border;
        color: $color-primary;
        font-size: $font-size-small;
        line-height: $line-height-small;
    }

    button:hover:enabled {
        background: $gray-lighter !important;
    }

    button:not(:last-child) {
        border-right: 0 !important;
    }

    button:last-child {
        border-top-right-radius: $border-radius-small;
        border-bottom-right-radius: $border-radius-small;
    }

    button:first-child {
        border-top-left-radius: $border-radius-small;
        border-bottom-left-radius: $border-radius-small;
    }

    .active {
        color: white !important;
        background-color: $color-primary !important;
    }
}

.count-buttons {
    @extend %footer-buttons;
    justify-content: left;
}

.scanability-buttons {
    @extend %footer-buttons;
    justify-content: right;
}
