// tools
.text-bold {
    font-weight: bold;
}
.text-light-gray {
    color: #6C757D;
}
.text-dark-gray {
    color: #343A40;
}
.color-white {
    color: #fff;
}
.color-blue {
    color: #0072D6;
}
.color-red {
    color: #0072D6;
}
.bg-transparent {
    background-color: transparent;
} 
.bg-blue {
    background-color: #0072D6;
}
.bg-white {
    background-color: #fff;
}
.mb-16{
    margin-bottom: 16px;
}
.mb-8{
    margin-bottom: 8px;
}
.mb-12{
    margin-bottom: 16px;
}
.mb-24{
    margin-bottom: 24px;
}
.mb-32{
    margin-bottom: 32px;
}
.mr-16 {
    margin-right: 16px;
}
.mr-4 {
    margin-right: 4px;
}
.mr-8 {
    margin-right: 8px;
}
.mr-32 {
    margin-right: 32px;
}
.mt-auto {
    margin-top: auto;
}
.p-0 {
    padding: 0;
}
.pr-0 {
    padding-right: 0px!important;
}
.pr-4 {
    padding-right: 4px;
}
.pr-8 {
    padding-right: 8px;
}
.px-16 {
    padding: 0 16px;
}
.m-0 {
    margin: 0;
}

.d-flex {
    display: flex;
    &.vertical-align {
        align-items: center;
    }
}
.flex-column  {
    flex-direction: column;
}
.d-none  {
    display: none;
}
.space-4 {
    gap: 4px;
}
.space-6 {
    gap: 6px;
}
.space-16 {
    gap: 16px;
}
.space-80 {
    gap: 80px;
}
.space-between {
    justify-content: space-between;
    align-items: center;
}
.align-center {
    align-items: center;
}
.align-end {
    justify-content: end;
}

.border-white {
    border: 1px #fff solid;
    color: #fff;
}
.border-blue {
    border: 1px #0072D6 solid;
}
.border-gray {
    border: 1px #6C757D solid;
}
.border-none {
    border: none;
}

.w-100 {
    width: 100%;
}
.w-16 {
    width: 16px;
}
.w-80 {
    width: 80%;
}
.w-20 {
    width: 20%;
}

.w-400{
    width: 400px;
}
.w-120 {
    width: 120px;
}
.w-136 {
    width: 136px;
}

.h-100 {
    height: 100%;
}

.vh-100 {
    height: 100vh;
}

.p-relative {
    position: relative !important;
}
.p-absolute{
    position: absolute;
}

.f-w-normal {
    font-weight: normal;
}

.no-text-break {
    white-space: nowrap;
}
.cursor-pointer {
    cursor: pointer;
}

// file attachment color
.icon-red {
    color: #ad0b00 ;
}

