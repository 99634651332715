@import './custom-scrollbar';

.tab-set-vertical {
    display: flex;

    .tab-content {
        padding-left: 20px;
        width: 100%;
        min-width: 0;
    }

    .uib-tab,
    .nav-item {
        text-align: center;
    }
}

/**
* Used to space fontawesome icons in tab headings evenly and allow title alignment
*/
.tab-title-icon {
    width: 20px;
    text-align: center;
}

.tab-pane {
    min-height: 100%;
}

uib-accordion .panel {
    border-color: $brand-primary;
    background-color: $white;

    .panel-title {
        color: $brand-primary;
    }
}

uib-accordion.inline-section {
    .panel-title {
        font-size: $font-size-base;
        font-weight: $fontNormal;
    }
}

uib-accordion {
    h5 {
        padding: 20px 15px;
        color: $color-primary;
        font-family: 'Lato', sans-serif;
        font-size: 17px;
        line-height: 22.5px;
        // display: inline-flex;
    }
}

/**
 * Make the tabset content expand to fill the size of the container
 */
tabset.fill {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    &.scroll {
        div.tab-content {
            max-height: 100%;
            overflow: auto;
            @extend .modal-scrollbar;
        }
    }
    div.tab-content {
        flex: 1;
        tab.active {
            display: flex;
            flex-direction: column;
            min-height: 100%;
            max-height: 100%;
        }
    }
    &.tab-set-vertical {
        flex-direction: row;
    }
}
