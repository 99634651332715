.cal-open-day-events {
    background-color: $color-comet-light;
}

.cal-month-view .cal-cell-top {
    min-height: 25px;
}

.cal-month-view .cal-day-cell.cal-in-month.cal-has-events {
  cursor: default; }

.cal-day-cell {
    min-height: 75px;
    max-height: 350px;
}

.cal-week-view .cal-event {
    text-decoration: none;
    color: $color-comet-dark;
    background-color: lighten($color-primary, 30%);
    border-color: lighten($color-primary, 30%);
    border-radius: 15px;
    padding: 5px;
    cursor: default;
}

.cal-event-container {
    font-size: 85%;
    padding: 5px;
    border-radius: 15px;
    background-color: lighten($color-primary, 30%);
    cursor: pointer;
}

.event-heading {
    font-weight: $fontBold;
    margin-bottom: 1px;
}

.calendar-heading {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;

    .view-buttons {
        position: absolute;
        right: 0;
        display: flex;
        justify-content: right;
    }
}