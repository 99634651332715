.sidebar {
    @include bg(#383838);
}

.main-content {
    height: 100%;
    overflow: hidden;

    ui-view {
        overflow: auto;
    }

    @media (min-width: $screen-xs-min) {
    }
    @media (min-width: $screen-sm-min) {
        //margin-left: $sidebar-width;
    }
}

// Signup responsive styles
.signup-main-content {
    @extend .main-content;
}

.signup-form-container {
    align-self: flex-end;
    margin-bottom: 20px;
}

@media only screen and (max-width: $screen-sm-max) {
    .signup-main-content {
        height: Calc(100vh - 25px);
        margin-top: 25px !important;
    }
    .signup-main-wrapper {
        flex-direction: column;
        height: Calc(100vh - 25px);
        max-height: Calc(100vh - 25px);
    }

    .signup-form-container {
        margin-bottom: 5px;
    }

    .signup-extra-content {
        overflow: auto;
        flex: 1;
        margin-bottom: 25px;
        #main-slider {
            margin-top: 0;
        }
    }
}

.relative {
    position: relative;
}
.d-flex {
    display: flex;
    &.vertical-align {
        align-items: center;
    }
}
.space-between {
    justify-content: space-between;
    align-items: center;
}

.mb-16{
    margin-bottom: 16px;
}
.mb-12{
    margin-bottom: 16px;
}
.mb-24{
    margin-bottom: 24px;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.widget {
    background-color: $color-alabaster;
    box-shadow: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.5);
    border-radius: 0;
    border: none;
    border-bottom: 0.1rem solid rgba(155, 155, 155, 0.1);
    margin: 0.3rem 0;
    z-index: 100;

    &.dark {
        background-color: #162233;
        color: $color-white;
    }

    .panel-heading-flex {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 46px;
        background-color: $bg-mission-widget;
        padding: 0 15px;
        color: $color-alabaster;
        white-space: nowrap;

        // Remove the standard label formatting
        label {
            margin-bottom: 0;
        }

        .panel-icon {
            position: relative;
            border-bottom: 65px solid $color-alabaster;
            border-left: 0px solid transparent;
            border-right: 27px solid transparent;
            border-top-left-radius: 5px;
            border-top-right-radius: 32px;
            width: 80px;
            margin-top: -19px;
            margin-left: -15px;
            margin-right: 15px;

            &.dark {
                border-bottom: 65px solid #162233;
            }

            img {
                border-radius: 50px;
                position: absolute;
                width: 40px;
                height: 40px;
                left: 15px;
                top: 15px;
                border: 2px solid $color-white;
                box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
            }
        }

        .panel-title {
            padding: 1px;
            color: $color-white;
            font-family: $font-family-primary;
            font-weight: 500;
            font-size: 1rem;
            text-transform: uppercase;
            &.no-uppercase {
                text-transform: initial;
            }
        }

        .panel-content {
            flex: 1;
            display: inline-flex;
            flex-grow: 2;
            align-items: center;
            font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
            margin-left: 5px;
            label {
                margin-right: 10px;
                font-weight: unset;
                font-size: 1rem;
            }
            .form-control {
                height: 25px;
                line-height: 25px;
                padding: 2px 10px;
            }
            ng-select {
                flex: auto;
                align-self: center;
            }
        }

        .panel-buttons {
            display: flex;
            justify-content: end;
            button {
                margin-left: 10px;
                display: flex;
                text-decoration: none;
                align-self: center;

                &:first-child {
                    margin-left: 25px;
                }

                .fa,
                .fas {
                    border-radius: 25px;
                    border: 2px solid;
                    padding: 6px;
                    font-size: 10px;
                    width: 27px;
                    height: 27px;
                    &.no-border {
                        border-radius: unset;
                        border: unset;
                        padding: unset;
                        font-size: inherit;
                        width: inherit;
                        height: inherit;
                    }
                }
            }

            a {
                color: white;
                align-self: center;

                &:hover {
                    color: #333;
                }
            }
        }
    }

    .panel-heading {
        border-radius: 0;
        border-bottom: 0;
        padding: 0.8rem 1.6rem 0.8rem 1.6rem;
        margin-bottom: 0;
        color: $color-white;
        font-family: $font-family-primary;
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.4rem;
        height: 3.1rem;
        text-transform: uppercase;

        .form-inline {
            select {
                max-width: 54%;
            }
        }

        .panel-heading-icon {
            position: absolute;
            border-bottom: 65px solid $color-alabaster;
            border-left: 0px solid transparent;
            border-right: 27px solid transparent;
            border-top-left-radius: 5px;
            width: 80px;
            left: 15px;
            top: 0px;

            img {
                border-radius: 50px;
                position: absolute;
                width: 40px;
                right: 0;
                top: 15px;
            }
        }
        .btn-link {
            padding: 2px 15px 0px 0px;
            outline: none;
        }
    }

    .pull-right {
        .btn-link {
            padding: 2px 20px 0px 0px;
            outline: none;
        }
    }

    .panel-heading {
        background-color: $bg-default-widget;
    }
}

.panel-heading,
.modal-header {
    .btn:not(.btn-primary):not(.btn-default):not(.btn-tertiary):not(.btn-pill):not(.btn-golden) {
        color: $color-white;
        border: none;
        background-color: transparent;
    }

    .btn:hover:not(.btn-primary):not(.btn-default):not(.btn-pill) {
        color: $color-black;
    }

    .btn:active:not(.btn-primary):not(.btn-default):not(.btn-pill) {
        box-shadow: none;
        -webkit-box-shadow: none;
    }
}

.panel-body .table {
    margin-bottom: 0;
    .pagination-sm {
        margin-bottom: 0;
    }
}

.panel {
    position: relative;
}

.modal-header {
    background-color: $bg-default-widget;

    .vertical-align {
        display: flex;
        align-items: center;
    }

    .modal-title {
        padding: 1px;
        color: $color-white;
        font-family: $font-family-primary;
        font-weight: 500;
        font-size: 1rem;
        text-transform: uppercase;
    }
}

.panel-heading .form-inline {
    margin-top: -1rem;
    margin-right: -2.2rem;
    height: 39px;
    line-height: 39px;
    display: flex;
    align-items: center;
}

.panel-heading .form-inline .btn-link {
    padding: 0.3rem 2rem;
}

.sidebar.sidebar-right {
    border-radius: 0px 0px 0px 6px;
}

.sidebar {
    height: 100vh;
    background: #383838;
    width: 100%;
    box-shadow: 2px 0px 5px $color-comet-dark;

    display: flex;
    flex-direction: row;

    @media (min-width: $screen-sm-min) {
        position: fixed;
        top: 0;
        left: 0;
        width: inherit;
        overflow: auto;
    }

    .navbar-default {
        background-color: $sidebar-bg;
    }

    &.active .btn-navbar-toggle {
        text-align: center;
    }

    nav {
        margin: 0px;
        position: relative;
        padding-bottom: $power-by-height;
        min-height: 100%;
    }
    .sidebar-options {
        overflow: hidden;
        padding-right: 5px;
        scrollbar-color: #7e7e9c transparent;
        scrollbar-width: thin;
        margin-top: 10px;
    }

    @media screen and (max-height: 820px) {
        .sidebar-options {
            overflow-y: auto;
        }
    }

    .sidebar-options::-webkit-scrollbar {
       
        border-radius: 3px;
        height: 0.375rem;
        width: 0.375rem;
        background-color: transparent;
    }
    .sidebar-bottom-options {
        margin-bottom: 32px !important ;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    nav.sidebar-logo {
        line-height: 100px;

        img.logo-image {
            max-height: 65px;
            max-width: 100%;
        }
    }

    .navbar-btn {
        margin-left: 10px;
        margin-right: 10px;
    }

    .nav-icon {
        display: inline-block;
        margin-right: 5px;
        width: 20px;

        &.active {
            display: none;
        }
    }

    .nav li .nav-link {
        @include fontStyle($color-white, 16px, $fontNormal, 24px);
        padding: 10px;
        padding-left: 25px;
        display: block;
        border-left: 4px solid $transparent;
        border-right: 5px solid $transparent;
        // border-bottom: 0.5px solid #f5f5f5;

        &.dropdown-item {
            padding: 0px;
            font-size: 12px;
        }

        &.active,
        &:hover {
            @include fontStyle($color-primary, 16px, $fontNormal, 24px);
            color: $color-primary;
            background-color: $sidebar-hover-bg;

            .nav-icon {
                display: none;

                &.active {
                    display: inline-block;
                }
            }

            .glyphicon.nav-icon {
                display: inline;
                color: $color-primary;
            }
        }

        .glyphicon.nav-icon {
            color: $color-comet-dark;
        }
        .nav li .nav-link .glyphicon.nav-icon {
            color: $color-comet-dark;
        }

        &.edit-icon {
            // color: $9b;
            border-left: none;
            border-bottom: none;
            padding-bottom: 0;
            text-align: right;
        }
        .logo-text {
            @include fontStyle($color-tundora, 16px, $fontBold, 19px);
            margin-top: 0;
            margin-bottom: 15px;
        }
        .logo-img {
            max-width: 100%;
            min-height: 50px;
            margin-bottom: 20px;
        }
    }

    .nav.subnav li .nav-link {
        @include fontStyle($color-white, 15px, $fontNormal, 19px);
        padding: 10px 10px 10px 50px;
        display: block;
        background-color: $sidebar-lv1-bg;
        // border-bottom: 0.5px solid #f5f5f5;

        &.active,
        &:hover {
            @include fontStyle($color-primary, 15px, $fontNormal, 19px);
            color: $color-primary;
            background-color: $sidebar-hover-bg;
        }
    }

    /** Make labels on check boxes show as pointers */
    label.nav-link {
        cursor: pointer;
    }

    .nav li .nav-title {
        // background-color: $sidebar-title-bg;
        text-align: center;
        text-decoration: underline;

        @include fontStyle($color-white, $font-size-base, $fontNormal, 19px);
        padding: 10px;
        display: block;

        &.dropdown-item {
            padding: 0px;
            font-size: 12px;
        }
    }

    .nav-heading {
        border-bottom: 1px solid;
    }

    .navbar-default .navbar-brand {
        padding: 5px;
        background-color: $color-white;
    }

    .navbar-default .navbar-brand img {
        max-width: none;
        max-height: $navbar-height - 10px;
    }

    .route-nav .nav-item,
    .route-nav .nav-item.nav-title {
        display: none;
    }

    .route-nav.on-route {
        .route-link {
            display: none;
        }

        .nav-item,
        .nav-item.nav-title {
            display: block;
        }
    }

    .name {
        cursor: pointer;
    }

    /* Collapsed side nav */
    .navbar.collapsed {
        $sidebar-width: 80px;
        min-width: 80px;
        max-width: 80px;
        text-align: center;
        .logo-container {
            width: 40px !important;
            height: 40px !important;
            margin: 0px auto 10px auto;
        }

        ul li a {
            padding: 20px 10px;
            text-align: center;
            font-size: 0.85em;
        }

        .block-when-collapsed {
            display: block;
        }

        .nav-item label span.name,
        .nav-item span span.name,
        .nav-item a span.name,
        .nav-item .nav-title,
        .nav-item .nav-item.nav-title,
        .hide-when-collapsed {
            display: none;
        }

        .nav li .nav-link {
            padding-left: 10px;
        }

        .nav-item {
            display: none;
        }

        .powered-by {
            padding: 10px;
            width: 60px;
        }
    }

    .mdc-switch:not(.mdc-switch--checked) .mdc-switch__track {
        background-color: #797d81 !important;
    }
    .mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb {
        background-color: #f8f9fa !important;
    }
    .mdc-switch.mdc-switch--checked .mdc-switch__track {
        background-color: #007deb !important;
    }
    .mdc-switch.mdc-switch--checked .mdc-switch__thumb {
        border-color: #f8f9fa !important;
    }
}

//  COLLAPSIBLE MENU

#sidebarCollapse i {
    transform: none;
}

#sidebarCollapse.active i {
    transform: rotate(-45deg) translate(1px, -1px);
}

#sidebar.active ul li a i {
    margin-right: 0;
    display: block;
    font-size: 1.8em;
    margin-bottom: 5px;
}

/* Same dropdown links padding*/
#sidebar.active ul ul a {
    padding: 10px !important;
}

/* Changing the arrow position to bottom center position,
   translateX(50%) works with right: 50%
   to accurately  center the arrow */
#sidebar.active a[aria-expanded='false']::before,
#sidebar.active a[aria-expanded='true']::before {
    top: auto;
    bottom: 5px;
    right: 50%;
    transform: translateX(50%);
}

.nav > li > a > img {
    max-height: 22px;
}

.footer {
    position: fixed;
    background-color: $color-white;
    bottom: 0;
    /* Set the fixed height of the footer here */
    height: 60px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.full-width {
    width: 100%;
}

.container-padding {
    // padding-left: ($grid-gutter-width/2);
    // padding-right: ($grid-gutter-width/2);
    padding-left: calc(#{$grid-gutter-width} / 2);
    padding-right: calc(#{$grid-gutter-width} / 2);

}

.align-center {
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.top-buffer {
    margin-top: 10px;
}

.bottom-buffer {
    margin-bottom: 10px;
}

.left-buffer {
    margin-left: 5px;
}

.right-buffer {
    margin-right: 5px;
}

.bottom-buffer {
    margin-bottom: 10px;
}

.micro-buffer {
    margin-top: 5px;

    @media (min-width: 768px) {
        margin-top: 0;
    }
}

.e-switch-wrapper .e-switch-on,
.e-css.e-switch-wrapper .e-switch-on {
    background-color: #000 !important;
}

.e-switch-wrapper .e-switch-handle.e-switch-active,
.e-css.e-switch-wrapper .e-switch-handle.e-switch-active {
    background-color: #f5f5f5 !important;
}

.e-switch-wrapper .e-switch-handle,
.e-css.e-switch-wrapper .e-switch-handle {
    background-color: gray !important;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

$screen-xl-min: 1500px;

@media (min-width: $screen-xl-min) {
    @include make-grid(xl);

    @include responsive-invisibility('.hidden-xl');
}

/**
 * This type of table is used just for layout, not displaying tabular data
 */
.layout-table {
    width: 100%;

    td,
    th {
        padding: 8px;
    }
}

/**
 * Data layout classes. This generic one targets all elements with the main styles.
 */
.data {
    padding: 0.4em;
    border-radius: 6px;
    margin-left: -0.4em;

    .display-label {
        display: inline-block;
        width: 200px;
    }

    &:hover {
        background-color: $gray-lighter;
    }
}
/**
 * This data layout class makes sure that divs have text aligned appropriately
 */
div.data {
    display: flex;
}
