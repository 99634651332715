.btn-control {
    font: bold 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
    background-color: #ffffff;
    color: #333;
    top: 20px;
    left: 50%;
    z-index: 1;
    padding: 5px 10px;
    border-radius: 3px;

    &:hover {
        background-color: #4ea0da;
    }
}

#flyfreelyMap {
    .text {
        position: absolute;

        p {
            color: transparent;
        }
    }
    .mapboxgl-canvas {
        width: 100% !important;
        height: 100% !important;
        border-radius: 10px;
    }
}

.scroll {
    .angular-mapboxgl-map {
        &::before {
            content: '';
            position: absolute;
            z-index: 2;
            margin-left: auto;
            margin-right: auto;
            height: 100%;
            width: 100%;
            background-color: rgba(black, .45);
            font-size: 22px;
            animation: fade 3s linear;
            border-radius: 10px;
        }
    }

    .text {
        z-index: 2;
        position: absolute;
        bottom: 50%;
        width: 100%;

        p {
            color: white !important;
            font-size: 22px;
        }
    }
}

@keyframes fade {
  0% { opacity: 0 }
  25% { opacity: 1 }
  100% { opacity: 0 }
}
