/* These values are measured for use in this file, they do not affect the actual modals*/
$modal-header-height: 35px + 2 * $modal-title-padding;
$modal-header-min-height: 53px;
$modal-footer-height: 43px + 2 * $modal-title-padding;
$modal-margin: 30px;
$modal-with-footer-content-height: $modal-header-height + $modal-footer-height +
    $modal-margin - 2 + 36px;
$modal-without-footer-content-height: $modal-header-height + $modal-margin - 2 +
    36px;
$modal-lg-min-height: calc(100vh - #{$modal-margin * 2 + 36px});
$modal-lg-body-min-height: 100%;

// Make sure headers/footers observe the border radius.
.modal-content {
    overflow: visible;
}

.modal {
    background-color: rgba(89, 89, 116, 0.7);
}

.no-scroll {
    overflow: hidden !important;
}

/* Force a minimum height so that the footer doesn't collapse when it has no contents */
.modal-footer {
    min-height: $modal-footer-height;
}

.modal-header .close {
    opacity: 1;
    color: white;
    padding: 5px 10px;
    margin-right: -10px;

    &:hover {
        color: black;
        text-shadow: 1px 0 black;
    }
}

.modal-header a span {
    vertical-align: middle;
}

.panel-heading-icon {
    // position: relative;
    border-bottom: 65px solid #fafafa;
    border-left: 0px solid transparent;
    border-right: 27px solid transparent;
    border-top-left-radius: 5px;
    border-top-right-radius: 32px;
    width: 80px;
    position: absolute;
    left: 0px;
    top: -12px;

    img {
        border-radius: 50px;
        position: absolute;
        width: 40px;
        height: 40px;
        left: 15px;
        top: 15px;
        border: 2px solid #fff;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    }
}

.modal-header {
    max-height: $modal-header-height;
    min-height: $modal-header-min-height;
    padding: 0px 25px 0px 125px;
    align-items: center;

    &.no-icon {
        padding: 0px 25px;
    }

    .btn-sm {
        padding: 2px 10px;
    }

    &.modal-upsell {
        background-color: $color-brand-golden !important;
        color: $color-brand-dark-gray !important;
        h4,
        span,
        button {
            color: $color-brand-dark-gray !important;
        }
    }
}

.modal-title {
    &.padding {
        padding: 5px 0;
    }
}

.modal-body {
    overflow-y: auto;
    overflow-x: hidden; /* I've done this to avoid dialogues to be horizontal scrollable */
    max-height: calc(100vh - #{$modal-with-footer-content-height});

    tabset {
        .nav {
            &.nav-pills {
                &.nav-stacked {
                    overflow-y: auto;
                    max-height: calc(
                        100vh - #{$modal-with-footer-content-height +
                            $modal-inner-padding * 2}
                    );
                    min-width: 200px;
                }
            }
        }
    }

    &.max-height {
        height: calc(100vh - #{$modal-with-footer-content-height});
    }

    &.no-footer,
    &.inner-container {
        max-height: calc(100vh - #{$modal-without-footer-content-height});
        .modal-body-content {
            max-height: calc(
                100vh - #{$modal-without-footer-content-height +
                    $modal-inner-padding * 2}
            );
        }

        tabset {
            .nav {
                &.nav-pills {
                    &.nav-stacked {
                        overflow-y: auto;
                        max-height: calc(
                            100vh - #{$modal-without-footer-content-height +
                                $modal-inner-padding * 2}
                        );
                        min-width: 228px;
                    }
                }
            }
        }
    }
    &.inner-container-and-footer {
        max-height: calc(100vh - #{$modal-with-footer-content-height});
        .modal-body-content {
            max-height: calc(
                100vh - #{$modal-with-footer-content-height +
                    $modal-inner-padding * 2}
            );
        }
        &.max-height {
            height: calc(
                100vh - #{$modal-with-footer-content-height +
                    $modal-inner-padding * 2}
            );
        }

        tabset {
            .nav {
                &.nav-pills {
                    &.nav-stacked {
                        overflow-y: auto;
                        max-height: calc(
                            100vh - #{$modal-with-footer-content-height +
                                $modal-inner-padding * 2}
                        );
                        min-width: 200px;
                    }
                }
            }
        }
    }
    &.inner-container,
    &.inner-container-and-footer {
        overflow-y: hidden;
    }

    &.no-padding {
        padding: 0px;
    }

    /**
     * Support putting the modal footer inside the body so we can have components that optionally provide a footer
     * looking container
     */
    .modal-footer {
        margin: 0 -30px;
        padding: 15px 30px 0;
        min-height: 0;
    }

    /**
     * Escape the modal body padding width
     */
    .modal-content-full-width {
        margin: 0 #{$modal-inner-padding * -1};
    }
}

.modal-body-content {
    // overflow: hidden;
    display: flex;
    flex-direction: column;

    &.container-scroll {
        @extend .container-scroll;
    }
}

.modal-dialog.modal-user-popup {
    width: 800px;
    min-height: 400px;
    .modal-body {
        padding: 0;
        padding-top: 5px;
    }
    div.col-xs-12 {
        padding: 0 5px;
    }
}

.modal-dialog.modal-lg {
    min-height: $modal-lg-min-height;
    .modal-content {
        min-height: $modal-lg-min-height;
    }
    .modal-body {
        min-height: calc(100vh - #{$modal-with-footer-content-height});
        .modal-body-content {
            flex: 1;
            min-height: calc(
                100vh - #{$modal-with-footer-content-height +
                    ($modal-inner-padding * 2)}
            );
            &.enhanced-help-spacing {
                margin-bottom: 45px;
            }
        }
        &.no-footer {
            &.inner-container {
                min-height: calc(
                    100vh - #{$modal-without-footer-content-height}
                ) !important;
            }
            .modal-body-content {
                min-height: calc(
                    100vh - #{$modal-without-footer-content-height +
                        ($modal-inner-padding * 2)}
                );
            }
        }
    }
}

.modal-dialog.modal-full {
    width: 100% !important;
    height: 100% !important;
    margin: 0;
    padding: 0;
    .modal-content {
        height: 100% !important;
        border: 0;
        border-radius: 0;
        background-color: rgba(0, 0, 0, 0.7);
    }
    .modal-header {
        padding: 14px 25px;
        background: none;
        border: none;
        text-align: left;
        min-height: 28px;
        .close:hover {
            color: $color-white;
            text-shadow: 1px 0 $color-white;
        }
    }
    .inner-content {
        height: calc(100vh - 116px);
        overflow-y: auto;
        margin: 0px 25px;
        position: relative;
        section {
            height: 100%;
            img {
                max-height: 100%;
                max-width: 100%;
                height: auto;
                width: auto;
            }
        }
    }
    .icon-red {
        color: $color-block;
    }
    .margin-auto {
        margin: 0 auto;
    }
    .btn-download-position {
        position: absolute;
        bottom: 60px;
        right: 30px;
    }
    .attachment-scale {
        height: 100%;
    }
}

.modal-dialog.modal-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    /* transform based on the modal size and padding and override the transition */
    transform: translate(Calc(-50% - 2.5px), Calc(-50% - 2.5px)) !important;
    margin: 0;
}

.modal-dialog.modal-help {
    width: 90%;
    max-width: 600px;
    top: 50vh;
    transform: translateY(-50%) !important;
}

.modal-dialog.modal-responsive {
    width: 740px;
    min-height: 400px;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
.modal-body::-webkit-scrollbar,
.modal-body-content::-webkit-scrollbar {
    background-color: #f1f1f1;
    width: 16px;
    border-radius: 16px;
}

/* background of the scrollbar except button or resizer */
.modal-body::-webkit-scrollbar-track,
.modal-body-content::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 16px;
}

/* scrollbar itself */
.modal-body::-webkit-scrollbar-thumb,
.modal-body-content::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #f1f1f1;
}

/* set button(top and bottom of the scrollbar) */
.modal-body::-webkit-scrollbar-button,
.modal-body-content::-webkit-scrollbar-button {
    display: none;
}

// Make the modal fullscreen on mobile
@media only screen and (max-width: $screen-xs-max) {
    .modal-dialog.modal-responsive {
        width: 100%;
        height: 100vh;
        min-height: 100%;
        margin: 0;
        overflow: hidden;

        .modal-body {
            max-height: unset;
            height: 100%;
            width: 100%;
            padding: 0;
        }

        .modal-content {
            height: 100%;
        }
    }
}

/* For task based modals */
.modal-dialog.modal-task,
.modal-dialog.modal-task-wide {
    .modal-content {
        overflow: hidden;
        border-radius: 6px;
        .modal-header {
            padding: 0.6rem 1.6rem;
            background-color: white;
            border-bottom: 1px solid #e5e5e5;
            .modal-title {
                color: #666;
                font-size: 16px;
            }

            .close {
                color: #666;
            }

            .btn {
                color: #666;
            }

            enhanced-help-switch {
                label {
                    color: #666;
                }

                .mdc-switch.mdc-switch--checked .mdc-switch__thumb {
                    border-color: #007dd5 !important;
                }

                .mdc-switch.mdc-switch--checked .mdc-switch__track {
                    background-color: #007dd5 !important;
                }
            }
        }

        .modal-body {
            background-color: white;
            display: flex;
            flex-direction: column;
            min-height: 50vh;

            &.max-height {
                min-height: calc(
                    100vh - #{$modal-with-footer-content-height} + 37px
                );
            }
        }

        .modal-footer {
            padding: 10px;
            background-color: white;
            min-height: unset;
            height: unset;
            .btn {
                font-size: 15px;
                padding: 5px 10px;
                line-height: 1.5;
                border-radius: 3px;
            }
        }
    }
}

.modal-task-wide {
    @media (max-width: $screen-md) {
        min-width: calc(100vw - 20px);
    }
    min-width: 900px;
}

/* For warnings */
.modal-dialog.modal-warning {
    @at-root body.mobile #{&} {
        max-width: calc(100vw - 20px);
    }
    .modal-content {
        overflow: hidden;
        border-radius: 6px;

        .modal-header {
            position: relative;
            padding: 0 25px 0 80px;
            background-color: #ffd53c;
            border-bottom: 1px solid #ffd53c;
            min-height: 62px;
            &.no-icon {
                padding: 0 25px;
            }
            .modal-title {
                color: #000;
                font-size: 24px;
                text-transform: uppercase;
                font-weight: bold;
            }
            .panel-heading-icon {
                border: unset;
                position: initial;
                width: inherit;
                img,
                span {
                    border-radius: 50px;
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    left: 10px;
                    top: 50%;
                    transform: translate(0, -50%);
                    border: 2px solid $color-white;
                    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
                }
                span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 22px;
                }
            }
        }

        .modal-body {
            background-color: white;
            display: flex;
            flex-direction: column;
        }

        .modal-footer {
            padding: 15px 30px;
            background-color: white;
        }

        @at-root body.mobile #{&} {
            border-radius: 0;
            overflow: visible;
            margin-top: 40px;
            .modal-header {
                border-bottom: unset;
                padding: 42px 10%;
                text-align: center;
                max-height: unset;

                .panel-heading-icon {
                    img,
                    span {
                        top: 0;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        font-size: 28px;
                        background-color: #ffd53c;
                        width: 62px;
                        height: 62px;
                        border-radius: 70px;
                        border: unset;
                        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
                    }
                    span {
                        line-height: 62px;
                    }
                }
            }
            .modal-body {
                overflow: hidden;
                padding: 20px 10% 0;

                p {
                    font-size: 1.5rem;
                }
            }

            .modal-footer {
                padding: 10px 5px;
                border-top: none;
                display: flex;
                justify-content: center;
                align-items: center;
                .btn {
                    font-size: 24px;
                    padding: 20px;
                    border: unset;
                    &:active,
                    &:hover {
                        background-color: unset;
                    }
                    &:hover {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

// override for datepicker z-value to ensure it shows on top everywhere
bs-datepicker-container,
bs-daterangepicker-container,
.container-z-override {
    z-index: 3001 !important;
}

// Ensure that popup elements are not behind other things
.dropdown-menu.modal-dropdown,
.uib-datepicker-popup,
body > .ui-select-bootstrap.open,
bs-dropdown-container,
.ng-dropdown-panel.ng-select-bottom {
    z-index: #{$zindex-modal * 2} !important;
}

// Scale up the modal
@media (min-width: $screen-sm-min) {
    .modal-lg {
        width: $modal-lg;
    }
}

.modal-footer {
    .btn-group + .btn,
    button,
    a,
    div.btn-group {
        // @extend .modal-footer .btn + .btn;
        margin-left: 5px;
    }
}

.modal-help-switch-container {
    height: 50px;
}

.modal-help-switch {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 25px 20px;
}
