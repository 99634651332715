/*** Style for Login & Signup Page ***/
@mixin fontStyleFront($color, $fontSize, $fontWeight, $lineHeight) {
    color: $color;
    font-family: $font-family-sans-serif;
    font-size: $fontSize;
    font-weight: $fontWeight;
    line-height: $lineHeight;

    &.disabled,
    &[disabled] {
        color: lighten($color, 30%);
    }
}
.clear-icon {
    cursor: pointer;
    position: absolute;
    color: #ccc;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.first-page {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 75%;
    display: flex;
    flex: 1;
    align-items: center;
    height: 100vh;
    width: 100%;
    justify-content: right;

    &.login {
        background-image: url('images/login-background.jpg');
    }

    &.signup {
        background-image: url('images/signup-background.jpg');
    }

    &.error {
        background-image: url('images/signup-background.jpg');

        .jumbotron {
            border-radius: 1.5rem;
            background: #ffffff99;
            margin: 0 auto;
        }
    }

    /* make the main content the full width */
    .main-content {
        margin: 0;
        width: 100vw;
    }

    footer {
        bottom: 0;
        position: absolute;
        width: 100%;
        display: block;
    }

    :focus {
        outline: none;
    }
}

// slope field variables
$slope-input-height: 4.5rem;
$slope-input-width: 100%;
$slope-input-max-width: 32rem;
$slope-input-md-max-width: 26rem;
$slope-input-sm-max-width: 20rem;
$slope-border-radius: 0.5rem;
$signup-sm-max-width: 15rem;

.login-btn-primary {
    background: transparent;
    border: 0;
    border-radius: 0.5rem;
    color: $white;
    font-family: Montserrat, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    //transform: skewX(-15deg);
    //-webkit-transform: perspective(15rem) rotateX(-15deg);
    //-o-transform: perspective(30rem) rotateX(-30deg);
    //-moz-transform: perspective(30rem) rotateX(-30deg);
    //-webkit-transform-origin: 0% 15%;
    //-moz-transform-origin: 0% 15%;
    //-o-transform-origin: 0% 15%;
    //transform-origin: 0% 15%;
    text-align: left;
    width: fit-content;
    width: -moz-fit-content;
    padding: 0 11rem 0 1rem;
    height: 3.1rem;
    line-height: 3.1rem;
    position: absolute;
    top: 0.5rem;
    left: 0;
    display: flex;

    &:hover {
        background: transparent !important;
        border: 0 solid transparent !important;
    }

    &.disabled,
    &[disabled] {
        background: transparent !important;
        border: none !important;
        & ~ div.slope-background {
            background: #595974 !important;
            border: 0.1rem solid #595974 !important;
        }
    }

    & + div.slope-background {
        background: #595974;
        border: 0.1rem solid #595974;
    }

    & ~ div.slope-background {
        background: $color-cornflower-blue;
        border: 0.1rem solid $color-cornflower-blue;
        border-radius: 0.5rem;
    }

    &:hover,
    &:focus {
        background: $color-cornflower-blue;
        border: 0.1rem solid $color-cornflower-blue;
    }

    &.md {
        max-width: $slope-input-md-max-width - 2.5rem;
        white-space: nowrap;
    }

    &.sm {
        max-width: $signup-sm-max-width - 2.5rem;
        white-space: nowrap;
    }

    &.disabled,
    &[disabled] {
        background: $color-comet-dark;
        border: 0.1rem solid $color-comet-dark;
    }
}

.f-link {
    color: $color-mine-shaft;
    font-family: Montserrat, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    border-bottom: 0.1rem solid $color-mine-shaft;
    display: inline-block;
    position: absolute;

    &:hover {
        text-decoration: none;
    }
}

.sign-link {
    @include fontStyleFront($color-mine-shaft, 1rem, 600, 1);
    display: block;
    margin-top: 6rem;
}

.sign-link:hover {
    text-decoration: none;
}

.logo-img {
    max-width: 100%;
    min-height: 5rem;
    margin-bottom: 3.5rem;
}

#main-slider {
    background: #f7f7f7;
    color: $color-cornflower-blue;
    opacity: 0.9;
    filter: alpha(opacity=90);
    -webkit-box-shadow: 0 5px 10px 3px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 10px 3px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px 3px rgba(0, 0, 0, 0.2);
    border-radius: 0.6rem;
    padding: 1.5rem;
    text-align: center;
    min-height: 40rem;
    margin: 100px auto 20px;

    .icon {
        width: 15rem;
        display: block;
    }

    .item {
        text-align: left;
        h4 {
            @include fontStyleFront(#184fb5, 2.2rem, 600, 1);
        }
        p {
            @include fontStyleFront(#184fb5, 1rem, 400, 1.5);
        }
    }

    .carousel-indicator {
        bottom: 0;
    }
    .box {
        p {
            max-width: 36rem;
            margin: 0 auto;
            width: 100%;
        }
    }
}

.signup-table {
    color: #184fb5;
    & td {
        text-align: left;
        padding-top: 2rem;
        padding-left: 1rem;
        font-size: 1rem;
        font-weight: 500;
    }
}


.alignleft {
    text-align: left;
}

.aligncenter {
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
    color: #676363;
    height: 3.1rem;
    line-height: 3.1rem;
    padding: unset;
}

.alignright {
    text-align: right;
}

.action-div {
    width: fit-content;
    display: flex;
    justify-content: space-between;
    height: 3.1rem;
}
.hr-vertical {
    height: 100%;
    width: 0;
    border: 0.8px solid #676363;
    margin: 0 2rem;
}

.f-link1 {
    color: #676363;
    font-family: Montserrat, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    height: 3.1rem;
    line-height: 3.1rem;

    &:hover {
        text-decoration: none;
    }
}

.boldTxt {
    font-weight: bold;
}

.visibleHidden {
    visibility: hidden;
}

.slope-input {
    background: $color-mercury;
    border: 1px solid $color-mercury;
    border-radius: $slope-border-radius;
    color: $color-mine-shaft;
    font-family: Montserrat, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    padding-left: 1rem;
    padding-right: 5px;
    margin-top: 0.5rem;
    margin-left: 1px;
    max-width: $slope-input-max-width - 2.5rem;
    width: calc(100% - 2.5rem);
    height: $slope-input-height - 1.5rem;

    &.ng-invalid.ng-dirty {
        border-color: transparent !important;
        & + div.slope-background {
            border: 1px solid #ff4d4f !important;
        }
        & ~ .slope-input-msg > div {
            margin-top: 5px;
        }
    }

    &:placeholder {
        color: $color-mine-shaft;
        font-family: Montserrat, sans-serif;
        font-size: 1rem;
        font-weight: 300;
    }

    &.md {
        max-width: $slope-input-md-max-width - 2.5rem;
    }

    &.sm {
        max-width: $slope-input-sm-max-width - 2.5rem;
    }

    &.error {
        border-color: $color-pomegranate;
    }

    &.error::placeholder {
        color: $color-pomegranate;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #676363;
}

.slope-background {
    background: $color-mercury;
    border: 1px solid $color-mercury;
    border-radius: $slope-border-radius;
    transform: skewX(-15deg);
    -webkit-transform: perspective(30rem) rotateX(-30deg);
    -o-transform: perspective(30rem) rotateX(-30deg);
    -moz-transform: perspective(30rem) rotateX(-30deg);
    -webkit-transform-origin: 0 15%;
    -moz-transform-origin: 0 15%;
    -o-transform-origin: 0 15%;
    transform-origin: 0 15%;
    max-width: $slope-input-max-width;
    width: $slope-input-width;
    height: $slope-input-height;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    &.slope-btn-background {
        position: relative;
    }
    &.md {
        max-width: $slope-input-md-max-width;
    }

    &.sm {
        max-width: $slope-input-sm-max-width;
    }

    &.signup-sm {
        max-width: $signup-sm-max-width;
    }
}
