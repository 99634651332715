@import 'common';

@import 'mapbox-gl/dist/mapbox-gl.css';
@import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
@import '@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css';

@import 'node_modules/@ng-select/ng-select/scss/default.theme.scss';
@import 'flags';
@import 'ngx-toastr-bs3';
@import '@circlon/angular-tree-component/css/angular-tree-component.css';

@import 'angular-calendar/css/angular-calendar.css';

@import 'custom-calendar';
@import 'node_modules/ngx-easy-table/style.scss';

@import '@videogular/ngx-videogular/fonts/videogular.css';

@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.bubble.css';
@import 'quill/dist/quill.snow.css';

.ql-editor p {
    line-height: 1.6;
}

.ql-mention-list-container {
    background-color: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
    overflow: auto;
    width: 270px;
    z-index: 9001;
}

.ql-mention-loading {
    font-size: 16px;
    line-height: 44px;
    padding: 0 20px;
    vertical-align: middle;
}

.ql-mention-list {
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
}

.ql-mention-list-item {
    cursor: pointer;
    font-size: 16px;
    line-height: 44px;
    padding: 0 20px;
    vertical-align: middle;
}

.ql-mention-list-item.disabled {
    cursor: auto;
}

.ql-mention-list-item.selected {
    background-color: #d3e1eb;
    text-decoration: none;
}

.mention {
    background-color: #d3e1eb;
    border-radius: 6px;
    height: 24px;
    margin-right: 2px;
    padding: 3px 0;
    user-select: all;
    width: 65px;
}

.mention > span {
    margin: 0 3px;
}

.mention-loading {
    padding: 8px;
    text-align: center;
    color: #999;
    font-style: italic;
}
