/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
.scrollbar::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
    border-radius: 16px;
}

/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 16px;
}

/* scrollbar itself */
.scrollbar::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {
    display:none;
}



/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
.modal-scrollbar::-webkit-scrollbar {
    background-color: #f1f1f1;
    width: 16px;
    border-radius: 16px;
}

/* background of the scrollbar except button or resizer */
.modal-scrollbar::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 16px;
}

/* scrollbar itself */
.modal-scrollbar::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #f1f1f1;
}

/* set button(top and bottom of the scrollbar) */
.modal-scrollbar::-webkit-scrollbar-button {
    display: none;
}
