
.section-heading.disabled {
    opacity: $disabled-opacity;
}

// Apply same disabled and readonly style and cursor tweak as for inputs
.form-group.disabled,
.input-group.disabled {
    .ng-select.ng-select-disabled > .ng-select-container, label {
        opacity: $disabled-opacity;
    }

    div {
        &.disabled {
            opacity: $disabled-opacity;
        }
    }

    .form-control,
    label,
    .input-group-addon,
    span,
    .btn-pill,
    .btn-tertiary,
    textarea {
        opacity: $disabled-opacity;
    }
}

// Apply same disabled cursor tweak as for inputs
// Some special care is needed because <label>s don't inherit their parent's `cursor`.
//
// Note: Neither radios nor checkboxes can be readonly.
input[type='radio'],
input[type='checkbox'] {
    &[disabled],
    &.disabled,
    fieldset[disabled] & {
        opacity: $disabled-opacity;
    }
}
// These classes are used directly on <label>s
.radio-inline,
.checkbox-inline {
    &.disabled,
    fieldset[disabled] & {
        opacity: $disabled-opacity;
    }
}
// These classes are used on elements with <label> descendants
.radio,
.checkbox {
    &.disabled,
    fieldset[disabled] & {
        label {
            opacity: $disabled-opacity;
        }
    }
}

.control-label-action-required {
    margin-right: -26px;
}
