@import '~@fortawesome/fontawesome-pro/css/all.min.css';
@import './custom-front-page';
@import './layout';
@import './containers';
@import './custom-table';
@import './custom-map';
@import './custom-tabs-accordions';
@import './custom-dialogues';
@import './custom-buttons';
@import './custom-forms';
@import './custom-scrollbar';
@import './custom-card';
@import './tools';

.cdk-drag-preview {
    // visibility: visible!important;
    display: flex !important;
    align-items: center;
    gap: 20px;
    position: absolute !important; /* Ensure it's positioned absolutely */
    opacity: 0.8 !important; /* Ensure it's visible */
    z-index: 10000 !important; /* Ensure it's on top */
}

.pagination {
    > .disabled {
        > span,
        > span:hover,
        > span:focus,
        > a,
        > a:hover,
        > a:focus {
            color: #6c757d !important;
        }
    }
}

.help-block,
.hint-block {
    color: $text-color;
    opacity: 0.7;
    font-style: italic;
    font-size: 14px;
}

.list-detail {
    display: inline-block;
    margin-right: 15px;

    img {
        margin-right: 5px;
    }
}

.pilot-container {
    position: relative;

    &:hover {
        .pilot-edit {
            opacity: 1;
        }
    }
}

.pilot-edit {
    -moz-transition: opacity 0.25s ease-in-out;
    -webkit-transition: opacity 0.25s ease-in-out;
    background: $color-comet-light;
    position: absolute;
    width: 100%;
    padding: 10px 0px;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
    top: -5px;
    bottom: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

// Typography

h2.content-title,
h3.content-title {
    margin-top: 0;
    margin-bottom: 10px;
}

.content-link {
    font-size: 13px;
    color: $color-comet-dark;
    font-weight: 500;
}

.micro-details {
    font-size: 14px;
}

b {
    color: $color-comet-dark;
    font-weight: $fontBold;
}

/* row-hover allows elements to be displayed only when the row is hovered over without affecting layout */
.row > .row-hover {
    visibility: hidden;
}

.row:hover > .row-hover {
    visibility: visible;
}

li {
    text-align: left;
}

li > .row-hover {
    visibility: hidden;
}

li:hover > .row-hover {
    visibility: visible;
}

tr .row-hover {
    visibility: hidden;
}

tr:hover .row-hover {
    visibility: visible;
}

// Colour

.purple {
    color: $color-primary;
}

// Forms

.radio-item {
    margin-bottom: 10px;
    padding-bottom: 8px;
    border-bottom: 1px solid $color-ghost;
}

// Lists

.mission-details {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
        margin-bottom: 10px;
    }
}

input.ng-invalid.ng-dirty,
select.ng-invalid.ng-dirty {
    border-color: $color-danger;
}

.error {
    color: $color-danger;
}

.warning {
    color: $color-warning;
}

.success {
    color: $color-success;
}

a:hover,
a:focus {
    text-decoration: underline;
    outline: none !important;
    cursor: pointer;
}

.input-group-btn .btn {
    padding: 6px 14px;
}

#mapid {
    height: 250px;
}

.uib-datepicker-popup {
    .btn-default {
        border: 0px;
    }
}

.label {
    font-size: 11px;
    line-height: 1.5;
    padding: 7px 12px;
}

.label-enhanced-help-warning {
    padding: 0.3em 0.9em 0.3em !important;
}

/* This is for the dialogues */
label,
.display-label {
    font-weight: bold;

    &.disabled {
        color: $gray-light;
    }
}

.inline-label {
    font-weight: bold;
    color: $color-brand-light-gray;

    &.disabled {
        color: $gray-light;
    }
}

@media screen {
    #printSection {
        display: none;
    }
}

.row.undo-row {
    margin-left: 0px;
    margin-right: 0px;
}

:focus {
    outline: 0;
}

$boxShadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

.bs {
    box-shadow: $boxShadow;
}

h1 {
    @include fontStyle(
        $color-tundora,
        $font-size-base * 2.8,
        $fontSemi,
        $font-size-base * 2.8
    );
    letter-spacing: $ls_1;
}

h2 {
    @include fontStyle(
        $color-tundora,
        $font-size-base * 2.3,
        $fontSemi,
        $font-size-base * 2.3
    );
    letter-spacing: $ls_1;
}

h3 {
    @include fontStyle(
        $color-tundora,
        $font-size-base * 1.8,
        $fontSemi,
        $font-size-base * 1.8
    );
}

h4 {
    @include fontStyle(
        $color-tundora,
        $font-size-base * 1.5,
        $fontSemi,
        $font-size-base * 1.5
    );
}

h5 {
    @include fontStyle(
        $color-tundora,
        $font-size-base * 1.2,
        $fontNormal,
        $font-size-base * 1.2
    );
}

p {
    @include fontStyle($text-color, $font-size-base, $fontNormal, 17px);
}

/** Spans have no extra line height so that they can be properly centered */
span {
    line-height: inherit;
}

html {
    // Fix for IE
    height: 100%;
    font-size: unset;
}

body {
    position: relative;
    height: inherit;
    // this is required because when tooltips initially get shown they cause an overflow which causes a scroll bar, which affects position calcs.
    overflow: hidden;
}

a {
    text-decoration: none;
    &:focus {
        text-decoration: none;
    }
    &:visited {
        text-decoration: none;
    }
    &:hover {
        text-decoration: none;
    }
}

.p-0 {
    padding: 0 !important;
}

.mb-3 {
    margin-bottom: 2rem !important;
}

.menu {
    max-width: 100px;
    margin-left: auto;
    position: relative;

    .user-dropdown {
        &:after {
            display: none;
        }

        .user-icon {
            width: 50px;
            border-radius: 50%;
            cursor: pointer;
        }
    }

    .fa {
        padding-left: 15px;
    }
}

.icon-plus {
    color: $color-tundora;
    font-size: 32px;

    &.success {
        color: $color-viking;
    }
    &.warning {
        color: $color-buttercup;
    }
    &.primary {
        color: $color-seagull;
    }
}

@media (min-width: 768px) {
    .p-5 {
        padding: 5rem;
    }
}

@media (max-width: 767px) {
    h2 {
        font-size: 20px;
        line-height: 28px;
    }
    .ai-table {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .padding-sm {
        padding: 3rem 15px !important;
    }
}

.menu .ai-dropdown-menu {
    .dropdown-item {
        padding-left: 0px;
        text-align: center;
    }
    text-align: center;
}

/*** Admin Page ***/

footer {
    display: none;
}

ul.airspace li {
    list-style: none;
}

#shortcutLinkContainer {
    span.editable-buttons {
        position: absolute;
        right: 22px;
    }
}

.mapboxgl-ctrl > .mapbox-gl-draw_ctrl-draw-btn.active {
    background-color: rgba(0, 0, 0, 0.2);
}

/*** Admin Craft Modal ***/

.grab-handle {
    cursor: move;
    cursor: -webkit-grab;
    margin-right: 6px;

    &:active {
        cursor: -webkit-grabbing;
    }

    &.small {
        width: 10px;

        .fas {
            font-size: 1.2em;
        }
    }
}

td {
    &.select {
        width: 10px;
    }
}

.nav-tabs > li.active > a > span {
    font-weight: bold;
    text-decoration: underline;
}

.nav-pills {
    display: flex;
    flex-direction: column;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    align-items: stretch;
    > li {
        > a {
            display: flex;
            gap: 8px;
            align-items: baseline;
            color: #162233;
            padding: 8px 0;
            text-align: left;
            border-radius: 3px;
        }
        // Active state
        &.active > a {
            &,
            &:hover,
            &:focus {
                color: #162233;
                background-color: #e9ecef;
            }
        }
    }
}

.popover-title {
    background-color: rgb(10, 88, 255);
    color: $color-polar;
}

/* Used to turn forms into inline elements */
.inline {
    display: inline;
}

/* Signup page  TODO refactor */
.features-main li {
    color: #474141;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 35px;
    padding-left: 30px;
    position: relative;
    list-style: none;
}

.features-main li::before {
    content: '';
    width: 20px;
    height: 24px;
    display: inline-block;
    position: absolute;
    background-image: url('images/check.png');
    left: 0;
    background-repeat: no-repeat;
    background-position: bottom;
}

.features-main li p {
    color: #9e9e9e;
    line-height: 20px;
    font-weight: 400;
}

.input-group-addon.input-group-addon-range {
    background: transparent;
    border-radius: $input-border-radius;
    border: 1px solid $input-group-addon-border-color;
}

.input-group,
.inner-addon {
    .input-group-clear {
        background: transparent;
        border: none;
        cursor: pointer;
        position: absolute;
        z-index: 1000;
        font-size: 12px;
        color: $color-tundora;
        &.calendar {
            top: 1px;
            right: 38px;
        }
        &.text {
            top: 7px;
            right: 8px;
        }
        &:hover {
            color: $color-torch-red;
        }
    }
    .input-clear-active {
        padding-right: 20px;
    }
}

// Aligns the contents of a div to its bottom.
.align-bottom {
    display: flex;
    align-items: flex-end;
}

.scroll-area {
    height: 300px;
    overflow-y: auto;
}

.black-text {
    color: black;
}

// Prevents textarea input from being resized horizontally
.textarea-lock-width {
    resize: vertical;
    min-height: 40px;
}

/* Style checkboxes in dropdown menus
* Copied from .dropdown-menu > li > a */
.dropdown-menu > li > label {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: $line-height-base;
    color: $dropdown-link-color;
    white-space: nowrap;
}

.vertical-align {
    display: flex;
    align-items: center;

    .workflow {
        .no-padding {
            padding-right: 0;
        }
    }
}

img {
    @include img-responsive;
}

// CHECKBOX AND RADION BUTTON OVERIDER

.checkbox label:after,
.radio label:after {
    content: '';
    display: table;
    clear: both;
}

.checkbox .cr,
.radio .cr {
    position: relative;
    // display: inline-block;
    border: 1px solid #a9a9a9;
    border-radius: 0.25em;
    width: 1.3em;
    height: 1.3em;
    float: left;
    margin-right: 0.5em;
}

.radio .cr {
    border-radius: 50%;
}

.checkbox .cr .cr-icon,
.radio .cr .cr-icon {
    position: absolute;
    line-height: 0;
    top: 50%;
    left: 20%;
}

.checkbox .cr .cr-icon {
    font-size: 0.8em;
}

.radio .cr .cr-icon {
    font-size: 0.7em;
}

.radio .cr .cr-icon {
    margin-left: 0.04em;
}

.checkbox {
    label {
        padding-left: 0px;
    }
}

.checkbox label input[type='checkbox'],
.radio label input[type='radio'] {
    display: none;
}

.checkbox label input[type='checkbox'] + .cr > .cr-icon,
.radio label input[type='radio'] + .cr > .cr-icon {
    transform: scale(3) rotateZ(-20deg);
    opacity: 0;
    transition: all 0.3s ease-in;
}

.checkbox label input[type='checkbox']:checked + .cr > .cr-icon,
.radio label input[type='radio']:checked + .cr > .cr-icon {
    transform: scale(1) rotateZ(0deg);
    opacity: 1;
}

.checkbox label input[type='checkbox']:disabled + .cr,
.radio label input[type='radio']:disabled + .cr {
    opacity: 0.5;
}

img.preview {
    max-width: 200px;
    max-height: 200px;
}

td .checkbox {
    margin: 0px;
}

.left-indent {
    padding-left: 15px;
}
.left-indent-sm {
    padding-left: 5px;
}
.right-indent {
    padding-left: 15px;
}
.right-indent-sm {
    padding-left: 5px;
}

.feature-toggle {
    margin-bottom: 10px;
}

.custom-dropdown {
    width: 250px;
    padding: 5px;
}

.no-margin {
    margin: 0px;
}

.recipient-checkbox {
    display: inline-flex;

    .checkbox {
        margin: 0px;
        margin-left: 10px;
    }
}

.pre-wrap {
    white-space: pre-wrap;
}

.no-wrap {
    white-space: nowrap;
}

.wrap-overflow {
    white-space: normal;
}

.section-heading {
    margin: 20px 0 10px;
    color: $color-primary;
    display: flex;
    align-items: center;

    span + div {
        margin-left: 10px;
        margin-top: -0.5px;
        height: 1px;
        background-color: $color-primary;
        flex: 1;
    }
}

.primary-heading {
    margin: 20px 0 10px;
    color: $color-primary;
    // Additional style to use when heading is at the top of a section to improve spacing
    &.top-heading {
        margin-top: 0;
    }
}

.secondary-heading {
    margin: 20px 0 10px;
    font-weight: 600;
}

.inline-heading {
    color: $color-primary;
}

// The sticky heading will act like a normal HTML element until it's scrolled to the top edge
// At that point it behaves more like position: absolute, but will revert if the view scrolls back down to its original position.
// Like position: absolute, the parent div will need to have a position: relative style for this to work.

.sticky-heading {
    // vendor fix for safari
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #fff;
}

h4.section-heading,
h4.inline-heading {
    font-size: 16px;
}

h5.section-heading {
    font-size: 15px;
}

h5.secondary-heading {
    font-size: 18px;
}

h4.popup-title {
    @include fontStyle(
        $color-tundora,
        $font-size-base * 2.3,
        $fontBold,
        $font-size-base * 2.3
    );
    margin: 0;
    padding: 15px 0 0;
}

h4.popup-heading {
    @include fontStyle(
        $color-tundora,
        $font-size-base * 2,
        $fontNormal,
        $font-size-base * 2
    );
    margin-top: 0;
    padding-top: 0;
}

p.popup-text {
    @include fontStyle($text-color, $font-size-base * 1.3, $fontNormal, 17px);
    margin-top: 10px;
}

p.popup-fine-print {
    @include fontStyle(
        $color-mine-shaft,
        $font-size-base * 0.8,
        $fontNormal,
        13px
    );
}

/**
 * Only display a thing when it is active
 */
.when-active {
    visibility: hidden;
}
.when-active.active {
    visibility: visible;
}

button.dragover {
    border: 2px #aaa dashed;
}

.badge {
    padding: 4px 16px;
    border-radius: 14px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;

    &.badge-pending {
        background: #feb272;
        color: #000;
    }

    &.badge-active {
        background: #0072d6;
        color: #fff;
    }

    &.badge-success {
        background: #30d455;
        color: #000;
    }

    &.badge-inactive {
        background: #a9a9bd;
        color: #000;
    }

    &.badge-danger {
        background: #dc3545;
        color: #fff;
    }

    &.badge-informational {
        background: #e9ecef;
        color: #000;
    }
}

.mission-info {
    .display-label {
        font-size: 13px;
    }

    .wrap {
        background-color: #f1f1f1;
        padding: 5px 15px;
        // display: inline;
        margin-left: -15px;
        border-radius: 10px;
        margin-bottom: 10px;
        min-height: 30px;
    }

    .status {
        padding: 0px 10px;
        // border-right: 1px solid black;
        margin-right: 10px;
    }
}

.mission-status-planning {
    background-color: transparent;
}

.mission-status-approved {
    background-color: #7ed321;
}

.mission-status-rejected {
    background-color: #d0021b;
}

.mission-status-waiting {
    background-color: #d8d8d8;
}

.mission-status-cancelled {
    background-color: transparent;
}

.rpa-status-serviceable,
.equipment-status-serviceable {
    color: #00de7a;
}

.rpa-status-unserviceable,
.equipment-status-unserviceable {
    color: #d0021b;
}

.rpa-status-retired,
.equipment-status-retired {
    color: #d0021b;
}

.rpa-status-under-maintenance,
.equipment-status-under-maintenance {
    color: #d0021b;
}

.inner-addon {
    span {
        color: rgba(77, 77, 77, 0.3);
    }
}

.form-flex {
    display: flex;
    align-items: center;

    input,
    select {
        flex: 1;
    }
}

/**
 * Styles for use with angular-file
 */
.invalid-drag {
    border: dotted 3px red;
}
.valid-drag {
    border: dotted 3px green;
}

.has-error .ng-touched .ng-select-container {
    border-color: $state-danger-text;
}

.row-even {
    background-color: $gray-lightest;
}

.row-padding {
    padding: 6px 0;
}

.row-warning {
    background-color: $color-warning;
}

[dropdown] {
    position: relative;
}

// styles for the status icons
.status-icon-block {
    position: relative;
    display: flex;

    .name-with-icon {
        padding-left: 8px;
    }
    .status-icon {
        width: 16px;
        height: 16px;

        div {
            position: absolute;
            width: inherit;
            height: inherit;
            border-radius: 100%;
            background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0.5) 0%,
                rgba(255, 255, 255, 0) 100%
            );
            border: 1px solid #ffffff;
            box-sizing: border-box;
            .dummy-icon {
                position: absolute;
                top: 0;
                left: 0;
                height: inherit;
                width: inherit;
                object-fit: contain;
            }
        }
    }
}

.status-icon-green {
    background-color: #00de7a !important;
}
.status-icon-yellow {
    background-color: #fecb1c !important;
}
.status-icon-grey {
    background-color: $color-ghost !important;
}
.status-icon-red {
    background-color: $color-pomegranate !important;
}
.status-icon-blue {
    background-color: #5899db !important;
}

.status-icon-green-dummy {
    background: linear-gradient(90deg, #00de7a 50%, transparent 50%) !important;
}
.status-icon-yellow-dummy {
    background: linear-gradient(90deg, #fecb1c 50%, transparent 50%) !important;
}
.status-icon-grey-dummy {
    background: linear-gradient(
        90deg,
        $color-ghost 50%,
        transparent 50%
    ) !important;
}
.status-icon-red-dummy {
    background: linear-gradient(
        90deg,
        $color-pomegranate 50%,
        transparent 50%
    ) !important;
}
.status-icon-blue-dummy {
    background: linear-gradient(90deg, #5899db 50%, transparent 50%) !important;
}

.ng-dropdown-panel.wide-panel {
    width: auto !important;
    max-width: 550px;
    min-width: 100%;
}

.ng-select.input-xs {
    .ng-select-container .ng-value-container .ng-input {
        top: 50%;
        transform: translateY(-50%);
    }
    .ng-select-container {
        min-height: 20px;
        height: 20px;
        max-height: 20px;
    }
}

.alert p {
    color: inherit;
    font: inherit;
}

// Additional disabled-state style for the alert element
// the bootstrap mixins are redefined here since importing them breaks some of this file's overrides
// The mixins here are reproduced from ./bootstrap/_theme.scss & ./bootstrap/mixins/_alerts.scss

@mixin alert-variant($background, $border, $text-color) {
    background-color: $background;
    border-color: $border;
    color: $text-color;

    hr {
        border-top-color: darken($border, 5%);
    }
    .alert-link {
        color: darken($text-color, 10%);
    }
}

@mixin alert-styles($color) {
    @include gradient-vertical(
        $start-color: $color,
        $end-color: darken($color, 7.5%)
    );
    border-color: darken($color, 15%);
}

.alert-disabled {
    @include alert-styles($color-brand-light-gray);
}

.alert-disabled {
    @include alert-variant(
        $color-ghost,
        $color-brand-light-gray,
        $color-brand-dark-gray
    );
}

// operation-failed is mainly used by an ng-template that builds off the ngx-loading element for dynamic alert overlays
.operation-failed-alert {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    z-index: 2000;
}

.status-block {
    border-radius: 5px;
    padding: 5px;
    margin-top: 5px;
    display: inline;
    color: #fff;

    &.active {
        background: #81d034;
    }

    &.pending {
        background: #f5a623;
    }

    &.inactive {
        background: #d03434;
    }

    &.disabled {
        background: #999;
    }

    &.custom {
        background: $color-brand-golden;
    }

    &.inverted {
        background: #fff;

        &.active {
            color: #81d034;
        }

        &.pending {
            color: #f5a623;
        }

        &.inactive {
            color: #d03434;
        }

        &.disabled {
            color: #999;
        }

        &.custom {
            color: $color-brand-golden;
        }
    }
}

// component specific ng select size
related-task-types {
    .ng-select.core-type {
        min-width: 120px;
    }

    .ng-select.task-type {
        min-width: 220px;
    }
}

// Couldn't figure out how to configure MDC directly.
.mdc-form-field > label {
    font-size: 14px;
    line-height: 1.428571429;
    color: #595974;
    margin: 0 8px 0 2px;
}

.ellipsis {
    @include text-overflow;
}
