.card-freely {
    min-height: 300px;
    min-width: 380px;
    width: 33vw;
    max-width: 500px;
    margin-bottom: 25px;
    // margin: 0 10px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 10px 1px #00000026;
}
.card-freely-container {
    flex-wrap: wrap;
    margin-top: 14px;
    padding: 1% 0;
}
.dependency-warning-container {
    min-width: 512px;
    width: 512px;
    display: flex;
    flex-direction: row;
    justify-content: start;

    .dependency-warning-popup {
        position: relative;
        display: flex;
        flex-direction: column;
        max-width: 80%;
        margin: 5px;
        border: #00000080 1px solid;
        border-radius: 5px;
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 10px;
            height: 10px;
            background: #fafafa;
            border-bottom: #00000080 1px solid;
            border-right: #00000080 1px solid;
            transform: translateX(-50%) translateY(60%) rotate(45deg);
        }

        .dependency-warning-heading {
            width: 100%;
            background: $color-bs-red;
            color: $color-brand-ash-white;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 5px 15px;
            line-height: 30px;
            font-size: 20px;
            font-weight: 500;
        }
        .close-warning-button {
            border: unset;
            background: transparent;
        }
        .dependency-warning-content {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 5px;
            text-wrap: wrap;
            text-align: center;
        }
    }
}

.card-freely-content {
    display: flex;
    flex-direction: column;
    height: 300px;
    max-height: 300px;
    padding: 10px 22px;
    &.active {
        border-left: $color-primary 8px solid;
    }
    &.action-required {
        border-left: $color-warning 8px solid;
    }
    &.incomplete {
        border-left: $color-bs-red 8px solid;
    }
    &.inactive {
        border-left: $color-comet-light 8px solid;
    }

    .authority-category {
        border-radius: 16px;
        margin-top: 5px;
        background: #e9ecef;
        color: $color-brand-dark-gray;
        width: 86px;
        height: 28px;
        min-height: 28px;
        text-align: center;
        line-height: 28px;
    }

    .authority-title {
        width: 100%;
        display: flex;
        text-wrap: balance;
    }
    .authority-status {
        border-radius: 16px;
        margin-top: 5px;
        color: $color-brand-dark-gray;
        min-width: 72px;
        height: 28px;
        min-height: 28px;
        text-align: center;
        line-height: 28px;
        &.active {
            background: #4dd4ac;
            color: $color-brand-dark-gray;
        }

        &.pending {
            background: #feb272;
            color: $color-brand-dark-gray;
        }

        &.inactive {
            background: $color-bs-red;
            color: $color-brand-ash-white;
        }

        &.disabled {
            background: #adb5bd;
            color: $color-brand-dark-gray;
        }

        &.action-required {
            background: #feb272;
            color: $color-brand-dark-gray;
            min-width: 11em;
        }
    }

    .authority-sub-title {
        color: $text-color;
        opacity: 0.7;
        text-wrap: balance;
        margin-top: 12px;
    }
}

.helper-content {
    width: 90%;
    margin: 0 auto 20px;
}
