// FLYFREELY COLOR PALETTES

$color-mine-shaft: #333030;
$color-mercury: #e8e8e8;
$color-comet-dark: #595974;
$color-comet: lighten($color-comet-dark, 15%);
$color-comet-light: lighten($color-comet-dark, 30%);
$color-ghost: #cacad8;
$color-white: #fff;
$color-black: #000;
$color-gallery: #eee;
$color-ocean-green: #42b983;
$color-pomegranate: #f44336;
$color-cornflower-blue: #4a7ad3;
$color-mischka: #d5d5dc;
$color-tundora: #4a4a4a;
$color-torch-red: #ff1721;
$color-lima: #7ed321;
$color-seagull: #6bbde9;
$color-viking: #5bd3d5;
$color-buttercup: #f5a623;
$color-alabaster: #fafafa;
$color-polar: #f4fafd;
$color-salmon: #ff936b;
$color-goldenrod: #ffd96b;
$color-blue-marguerite: #6669cb;
$color-coral: #ff8558;
$color-caribbean-green: #00de7a;
$color-yellow-orange: #ffa83d;
$color-sunset-orange: #ff4d4f;
$color-bs-red: #dc3545;
$color-naples-yellow: #fad65c;
$color-cornsilk: #fef7de;
$color-ufo-green: #30d455;
$color-silver: #cccccc;
$color-maximum-red: #db1f1f;
$color-true-blue: #007dd5;

$transparent: transparent;

// FLYFREELY COLOR BASE

// Used for upsell
$color-brand-golden: #ffb500;
$color-brand-light-gray: #939598;
$color-brand-gray: #363839;
$color-brand-dark-gray: #231f20;
$color-brand-ash-white: #f1f0f0;
$color-brand-slate-blue: #687a8f;

$color-primary-dull: #5899db;
$color-primary: #007dd5;
$color-primary-hover: #005fa2;

$color-success: $color-caribbean-green;
$color-warning: $color-yellow-orange;
$color-danger: $color-sunset-orange;
$color-white-2: #fefefe; // TODO try to remove this white

// AIRSPACE CHECKER & AUTHORISATION COLOR PALETTES

$color-pass: $color-ufo-green;
$color-block: $color-maximum-red;
$color-attention: $color-naples-yellow;
$color-attention-disabled: $color-cornsilk;
$color-unavailable: $color-silver;
$color-complete: $color-true-blue;

// FLYFREELY WIDGET COLOR PALETTES

$bg-default-widget: $color-primary;
$bg-mission-widget: $color-primary;
$bg-craft-widget: $color-primary;
$bg-pilot-widget: $color-primary;
$bg-battery-widget: $color-primary;
$bg-batteryset-widget: $color-primary;
$bg-maintenance-widget: $color-primary;
$bg-equipment-widget: $color-primary;
$bg-location-widget: $color-primary;
$bg-utilities-widget: $color-primary;

// FLYFREELY FONTS
$font-family-primary: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-secondary: 'Montserrat', sans-serif;
$text-color: $color-comet-dark;
$default-font-family: 'Lato', sans-serif;

// FLYFREELY OTHER TODO remove

$power-by-height: 110px;
$ls_1: 1px;

// FLYFREELY SIDEBAR

$sidebar-width: 265px;

// FLYFREELY FONT WEIGHT

$fontLight: 300;
$fontNormal: 400;
$fontSemi: 600;
$fontBold: 700;

// FLYFREELY INPUT

$input-border: $color-mischka;
$input-border-radius: 60px;

// BOOTSTRAP FONTS

$font-size-base: 14px;
$font-size-h1: floor(($font-size-base * 2));
$font-size-h2: floor(($font-size-base * 1.25));
$font-size-h3: $font-size-base;

// BOOTSTRAP COLOR PALETTES (OVERRIDE)

$brand-primary: $color-primary !default;
$brand-success: $color-success !default;
$brand-warning: $color-warning !default;
$brand-danger: $color-danger !default;
$white: $color-white !default;
$label-default-bg: $color-comet-dark !default;
$well-bg: $color-white;

$disabled-opacity: 0.2 !default;

$well-border: 0 !default;
$popover-max-width: 500px !default;
$headings-color: $color-comet-dark;

// BOOTSTRAP MODALS

$modal-inner-padding: 25px;
$modal-title-padding: 25px;
$modal-content-border-color: 0;
$modal-content-fallback-border-color: 0;
$modal-backdrop-bg: $color-comet-dark;
$modal-backdrop-opacity: 0.85;
$modal-md: 690px !default;
$modal-lg: 98% !default;

// BOOTSTRAP NAVBAB

$navbar-height: 70px;
$navbar-default-link-color: $color-comet-dark;
$navbar-default-link-hover-color: $color-primary;
$navbar-default-link-hover-bg: $color-gallery;
$navbar-default-link-active-color: $color-primary;
$navbar-default-link-active-bg: $color-gallery;
$nav-tabs-active-link-hover-bg: $color-white-2;

$border-radius-small: 3px;

$ng-select-height: 34px;

$sidebar-bg: #333;
$sidebar-lv1-bg: $sidebar-bg; //lighten(#272727, 10%);

$sidebar-title-bg: #3a3a3a;
$sidebar-hover-bg: #222;
